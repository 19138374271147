import { loadStripe } from '@stripe/stripe-js'

const stripePublicKey = process?.env.REACT_APP_STRIPE_PUBLIC_KEY

if (!stripePublicKey) {
  console.error('**Stripe publishable key environment variable not set**')
  console.error('**Add an environment variable REACT_APP_STRIPE_PUBLISHABLE_KEY**')
  console.error('**Replace .env.example with .env and **')
}

export const stripePromise = loadStripe(stripePublicKey)

const defaults = { stripePromise }

export default defaults
