import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyValidateSessionQuery } from '@documintApi/auth'
import { selectProfile, setProfile, setAccount } from './authSlice'
import {
  useLoginMutation,
  useLogoutMutation,
  useRecoverPasswordMutation,
  useResetPasswordMutation,
  useRegisterMutation,
  useValidatePasswordResetTokenQuery,
} from '../../services/documintApi/auth'
import { documintApi } from '../../services/documintApi'

/**
 * Registration Hook
 */
export const useRegister = () => {
  const dispatch = useDispatch()
  const [_register, { isLoading: isRegistering, error }] = useRegisterMutation()

  const register = useCallback(
    async (user, captcha) => {
      const { data, error } = await _register(user, captcha)

      if (error) throw error

      dispatch(setProfile(data.profile))
      dispatch(setAccount(data.account))
      return data
    },
    [_register, dispatch]
  )

  return [register, { isRegistering, error }]
}

/**
 * Login Hook
 */
export const useLogin = () => {
  const dispatch = useDispatch()
  const [_login, { isLoading }] = useLoginMutation()
  const login = useCallback(
    async (values) => {
      const { data, error } = await _login(values)
      if (error) throw error
      dispatch(setProfile(data.profile))
      dispatch(setAccount(data.account))

      return data
    },
    [_login, dispatch]
  )

  return [login, { isLoggingIn: isLoading }]
}

/**
 * Logout Hook
 * @returns
 */
export const useLogout = () => {
  const [_logout, { isLoading: isLoggingOut }] = useLogoutMutation()
  const dispatch = useDispatch()

  const logout = useCallback(async () => {
    await _logout()

    dispatch(setProfile(null))
    dispatch(setAccount(null))
    dispatch(documintApi.util.resetApiState())
  }, [_logout, dispatch])

  return [logout, { isLoggingOut }]
}

/**
 * Use Recover Password
 */

export const useRecoverPassword = () => {
  const [_recover, { isLoading: isRecovering }] = useRecoverPasswordMutation()
  const recover = useCallback(
    async (payload) => {
      const { data, error } = await _recover(payload)
      if (error) throw error
      return data
    },
    [_recover]
  )
  return [recover, { isRecovering }]
}

export const useValidatePasswordResetToken = (token) => {
  const { isLoading: isValidating, error } = useValidatePasswordResetTokenQuery(token)
  const isValid = !error
  return { isValid, isValidating }
}
/**
 * Use Reset Password
 */
export const useResetPassword = () => {
  const [_reset, { isLoading: isResetting }] = useResetPasswordMutation()
  /**
   * Reset Password
   * @param {*} password
   * @param {*} token
   * @returns
   */
  const reset = useCallback(
    async (password, token) => {
      const { data, error } = await _reset({ password, token })
      if (error) throw error
      return data
    },
    [_reset]
  )
  return [reset, { isResetting }]
}

/**
 * Session Validation Hook
 * @returns
 */
export const useValidateSession = () => {
  const profile = useSelector(selectProfile)
  const dispatch = useDispatch()
  const [_validate, { isFetching, error }] = useLazyValidateSessionQuery()
  const [isValid, setIsValid] = useState(false)
  const [isValidating, setIsValidating] = useState(false)

  const hasValue = (value) => value !== null && value !== undefined

  useEffect(() => {
    if (!isValid && hasValue(profile)) setIsValid(!!profile)

    if (error && isValid) setIsValid(false)

    if (isFetching !== isValidating) setIsValidating(isFetching)
  }, [error, profile, isFetching, isValid, isValidating])

  /**
   * Manually call the validate fn
   */
  const validate = async () => {
    if (isFetching) return

    const { data } = await _validate({}, false)

    const hasSession = !!data?.user
    const profileIsSet = !!profile
    const _isValid = hasSession

    if (hasSession && !profileIsSet) {
      dispatch(setProfile(data.user))
      dispatch(setAccount(data.account))
    }

    if (!hasSession && profileIsSet) {
      dispatch(setProfile(null))
      dispatch(setAccount(null))
    }

    if (_isValid !== isValid) setIsValid(_isValid)
    return _isValid
  }

  return [validate, { isValid, isValidating }]
}

/**
 *
 * @returns
 */
export const useGetProfile = () => {
  return useSelector(selectProfile)
}
