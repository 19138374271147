import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react'
import { useGetTemplateQuery, useUpdateTemplateMutation } from '../services/documintApi/templates'
import { useGetAssetsQuery } from '../services/documintApi/assets'

import { useToggle } from '../hooks/helperHooks'
import { useStackState } from '../hooks/helperHooks/useStack'
import { getObjectChanges } from '../utils'
import { zoomIn, zoomOut, setZoom } from '../components/Templates/Template/Designer/Editor/utils'
import _ from 'lodash'
import { getJsonSchemaFromTemplate } from './../utils/json'
import Handlebars from 'handlebars'
import { MODES } from '../components/Templates/Template/consts'

const DesignerContext = createContext()

export function useDesignerContext() {
  return useContext(DesignerContext)
}

const SCHEMA_PROPS = ['html', 'css', 'documentNamePattern', 'headerTemplate', 'footerTemplate']

export function DesignerProvider({ id, children }) {
  /**
   * TEMPLATE
   */
  const { data, isLoading, error: loadingError, isFetching, refetch } = useGetTemplateQuery(id)
  const [autoSave, toggleAutoSave] = useToggle(true)
  const prevViewModeRef = useRef()

  const [updateTemplate, templateUpdate] = useUpdateTemplateMutation()
  const {
    isLoading: isSaving,
    error: savingError,
    isSuccess: isSavingSuccess,
    fulfilledTimeStamp: savedAt,
    reset,
  } = templateUpdate

  // Get list of helpers to remove from schema
  const helperNames = Object.keys(Handlebars.helpers)

  const getUpdatedSchema = useCallback(
    (updates = {}) => {
      const filteredUpdates = _.pick(updates, SCHEMA_PROPS)
      if (_.isEmpty(filteredUpdates)) return data.fieldSchema
      const currentValues = _.pick(data, SCHEMA_PROPS)
      const values = { ...currentValues, ...filteredUpdates }
      const bodySchema = getJsonSchemaFromTemplate(values.html)
      const headerSchema = getJsonSchemaFromTemplate(values.headerTemplate)
      const footerSchema = getJsonSchemaFromTemplate(values.footerTemplate)
      const documentNameSchema = getJsonSchemaFromTemplate(values.documentNamePattern)
      const updatedSchema = _.merge({}, bodySchema, headerSchema, footerSchema, documentNameSchema)

      // remove handlebar helpers from the schema
      updatedSchema.properties = _.pickBy(updatedSchema.properties, (value, key) => helperNames.includes(key) === false)

      return updatedSchema
    },
    [data, helperNames]
  )

  const update = useCallback(
    async (patch) => {
      // Update the field schema if necessary
      const updatedSchema = getUpdatedSchema(patch)
      const fieldSchemaChanged = !_.isEqual(updatedSchema, data.fieldSchema)
      if (fieldSchemaChanged) patch.fieldSchema = updatedSchema

      // Add the version to the request if it exists
      if (data.__v) patch.__v = data.__v
      const response = await updateTemplate({ id, ...patch })
      return response
    },
    [id, data, updateTemplate, getUpdatedSchema]
  )

  const template = {
    data,
    isLoading,
    isFetching,
    loadingError,
    isSaving,
    isSavingSuccess,
    savingError,
    savedAt,
    autoSave,
    toggleAutoSave,
    reload: () => {
      _setEditor(null)
      reset()
      refetch()
    },
    update,
    /**
     * Updates content
     * @param {Object} content
     * @param {Object} content.components
     * @param {Object} content.styles
     * @param {String} content.html
     * @param {String} content.css
     */
    updateContent: useCallback(
      (content) => {
        const contentProps = ['components', 'styles', 'html', 'css']
        const changes = getObjectChanges(data, content, contentProps)
        if (!_.isEmpty(changes)) update(changes)
      },
      [data, update],
    ),
    /**
     * Updates assets
     * @param {String[]} assets
     */
    updateAssets: useCallback(
      (assets) => {
        if (!assets) return
        const currentAssetIds = data?.assets?.map((a) => (a?.id ? a.id : a))
        if (_.isEqual(currentAssetIds, assets)) return
        return update({ assets })
      },
      [data, update],
    ),
    /**
     * Use to update settings
     */
    updateSettings: useCallback(
      (settings) => update({ ..._.pick(settings, ['documentNamePattern', 'options', 'renderEngine']) }),
      [update],
    ),
    updateHeaderFooterTemplate: useCallback(
      (updates) => update({ ..._.pick(updates, ['headerTemplate', 'footerTemplate']) }),
      [update],
    ),
    /**
     * Updates test Data
     * @param {Object} testData
     * @returns
     */
    updateTestData: useCallback((testData) => update({ testData }), [update]),
    activate: useCallback(() => update({ isActive: true }), [update]),
    deactivate: useCallback(() => update({ isActive: false }), [update]),
    rename: useCallback((name) => update({ name }), [update]),
  }

  /**
   * EDITOR
   */
  const [_editor, _setEditor] = useState()
  const editorRef = useRef(null)
  const [selectedComponent, setSelectedComponent] = useState()
  const [zoomValue, setZoomValue] = useState(100)
  const [guides, toggleGuides] = useToggle(true)
  const [changeCount, setChangeCount] = useState(0)
  const [hasRedo, setHasRedo] = useState(false)
  const [hasUndo, setHasUndo] = useState(false)
  const [hasLegacy, setHasLegacy] = useState(false)
  const [revertToLegacy, setRevertToLegacy] = useState(false)
  const [revertSections, revertSectionsControls] = useStackState([])

  const handleToggleGuides = useCallback(() => {
    if (guides) {
      _editor.Commands.stop('sw-visibility')
    } else {
      _editor.Commands.run('sw-visibility')
    }
    toggleGuides()
  }, [guides, _editor, toggleGuides])

  // Add event listener for updating local unsavedChange count
  useEffect(() => {
    if (editorRef.current || !_editor) return
    editorRef.current = _editor
    _editor.on('change:changesCount', (editor, count) => {
      setChangeCount(count)
    })
  }, [_editor])

  const editor = {
    current: _editor,
    set: _setEditor,
    run: useCallback(
      (command) => {
        if (_editor) _editor.Commands.run(command)
      },
      [_editor]
    ),
    stop: useCallback(
      (command) => {
        if (_editor) _editor.Commands.stop(command)
      },
      [_editor]
    ),

    undo: useCallback(() => _editor?.Commands?.run?.('core:undo'), [_editor]),
    redo: useCallback(() => _editor?.Commands?.run?.('core:redo'), [_editor]),
    clear: useCallback(() => {
      _editor?.Components?.clear?.()
      _editor?.select?.()
    }, [_editor]),
    hasUndo,
    hasRedo,
    setHasUndo,
    setHasRedo,
    hasLegacy,
    setHasLegacy,
    revertToLegacy,
    setRevertToLegacy,
    revertSections,
    revertSectionsControls,
    showCode: useCallback(() => _editor?.Commands?.run?.('export-template'), [_editor]),

    // SELECTED COMPONENT
    selectedComponent,
    setSelectedComponent,

    // GUIDES
    guides,
    toggleGuides: handleToggleGuides,

    // ZOOM
    zoomIn: useCallback(() => {
      const value = zoomIn(_editor, 10)
      setZoomValue(value)
    }, [_editor]),
    zoomOut: useCallback(() => {
      const value = zoomOut(_editor, 10)
      setZoomValue(value)
    }, [_editor]),
    resetZoom: useCallback(() => {
      const value = setZoom(_editor, 100)
      setZoomValue(value)
    }, [_editor]),
    zoomValue,
    store: () => _editor.store(),
    changeCount,
  }

  const [headerFooter, headerFooterToggle] = useToggle(false)

  const modesListRef = useRef([
    { name: MODES.design, value: MODES.design },
    { name: MODES.preview, value: MODES.preview },
  ])
  const [viewMode, setViewMode] = useState(MODES.design)
  const modes = {
    previous: prevViewModeRef.current,
    current: viewMode,
    set: useCallback(
      (mode) => {
        setViewMode((prev) => {
          if (mode !== prev && prev === MODES.design) headerFooterToggle(false)
          prevViewModeRef.current = prev
          return mode
        })
      },
      [headerFooterToggle]
    ),
    get: useCallback((value) => modesListRef.current.find((m) => m.value === value), [modesListRef]),
    list: modesListRef.current,
  }

  // LIST ASSETS
  const assets = useGetAssetsQuery()

  const value = {
    template,
    editor,
    assets,
    modes,
    headerFooter,
    headerFooterToggle,
  }

  return <DesignerContext.Provider value={value}>{children}</DesignerContext.Provider>
}

const designerContext = { DesignerProvider, useDesignerContext }

export default designerContext
