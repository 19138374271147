import React, { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, Empty, Row, Col } from 'antd'
import { DownloadOutlined, PrinterOutlined, ShareAltOutlined } from '@ant-design/icons'
import PasswordPrompt from '../../common/PasswordPrompt'
import FixedHeaderLayout from '../../../layouts/FixedHeaderLayout'
import PDFViewer from '../../common/PDFViewer'
import { useDocument } from '../../../hooks/documentHooks'
import { download, print } from './../../../utils/file'
import isNil from 'lodash/isNil'
import LoadingDocument from '../../common/LoadingDocument'
import ShareModal from './ShareModal'
import { useToggle } from '../../../hooks/helperHooks'

const { Title } = Typography

export default function Document() {
  const { id } = useParams()
  const [password, setPassword] = useState(null)
  const document = useDocument(id, password)
  const { data = {}, isPasswordProtected, logoUrl } = document
  const [url, setUrl] = useState(null)
  const { isExpired, filename } = data
  const [share, toggleShare] = useToggle(false)
  const { origin } = window.location
  const isPreview = id === 'preview' || data.isPreview || false
  const shareUrl = isPreview === false && `${origin}/share/documents/${id}`

  const logo = {
    src: logoUrl,
    link: logoUrl ? null : '/',
  }

  useEffect(() => {
    if (data.url !== url) setUrl(data.url)
  }, [data, url])

  const handleSubmitPassword = ({ password }) => setPassword(password)

  const handleDownload = useCallback(async () => {
    try {
      await download(url, filename)
    } catch (error) {
      console.log({ error })
    }
  }, [url, filename])

  const handlePrint = useCallback(async () => {
    try {
      await print(url, filename)
    } catch (error) {
      console.log({ error })
    }
  }, [url, filename])

  const actions = [
    { label: 'Print', icon: <PrinterOutlined />, onClick: handlePrint },
    { label: 'Download', icon: <DownloadOutlined />, onClick: handleDownload },
  ]

  if (!isPreview) actions.push({ label: 'Share', icon: <ShareAltOutlined />, onClick: toggleShare })

  return (
    <FixedHeaderLayout
      logoSrc={logo.src}
      logoLink={logo.link}
      actions={!isPasswordProtected && !isPreview && !isExpired && actions}
      showHeader={!document.isLoading}
    >
      {isExpired ? (
        <Row align='middle' justify='center' style={{ height: 'calc(100vh - 200px)' }}>
          <Col>
            <Title level={2} className='text-center mb-5'>
              Document not found
            </Title>
            <Empty
              image='/images/empty-states/empty-bin.svg'
              imageStyle={{
                height: 180,
              }}
              description='This document has expired or was deleted.'
            />
          </Col>
        </Row>
      ) : document.isLoading ? (
        <LoadingDocument className='pt-4' />
      ) : document.isPasswordProtected ? (
        <div className='d-flex flex-row justify-content-center h-100'>
          <PasswordPrompt onSubmit={handleSubmitPassword} />
        </div>
      ) : (
        <PDFViewer
          error={document.error}
          showErrorDetails={true}
          data={url}
          branding={isNil(logoUrl)}
          isLoading={document.isLoading}
          height='calc(100vh - 64px)'
        />
      )}
      <ShareModal visible={share} url={shareUrl} onCancel={toggleShare} />
    </FixedHeaderLayout>
  )
}
