import React, { useState, useRef } from 'react'
import { pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import DefaultViewer from './DefaultViewer'
// import BrowserViewer from './BrowserViewer'

import { SizeMe } from 'react-sizeme'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

/**
 *
 * @param {Object|Url} data
 * @param {Boolean} browserViewer uses the browsers built in viewer instead
 * @param {Boolean} branding displays documint branding at bottom
 * @returns
 */
export default function PDFViewer({
  data,
  browserViewer,
  height,
  onDownloadSuccess,
  controls,
  isLoading,
  error,
  loaderProps,
  branding,
  padding,
  children,
}) {
  const frameRef = useRef()
  const [frame] = useState({ width: null, height: null, ref: frameRef })

  const sharedProps = { data, frame, children, isLoading, error }

  const customViewerProps = { loaderProps, onDownloadSuccess, controls, branding, padding }

  return (
    <SizeMe monitorHeight={true}>
      {({ size }) => (
        <div
          id='pdf-viewer'
          className='pdf-viewer pdf-viewer__outer-frame'
          ref={frameRef}
          style={{ width: `100%`, height: height || 'auto' }}
        >
          <DefaultViewer {...sharedProps} {...customViewerProps} />
        </div>
      )}
    </SizeMe>
  )
}

PDFViewer.defaultProps = {
  browserViewer: false,
  showDownloadButton: false,
  branding: false,
  padding: 20,
}
