import React, { useState } from 'react'
import { Form, Input, Button, Alert } from 'antd'
import { useResetPassword } from '../authHooks'
import { useParams } from 'react-router-dom'

export default function ResetPasswordForm({ onSubmit, onError }) {
  const [resetPassword, { isResetting }] = useResetPassword()
  const { token } = useParams()
  const [error, setError] = useState(null)

  const handleSubmit = async (values) => {
    try {
      const { password } = values
      await resetPassword(password, token)
      onSubmit && onSubmit(values)
    } catch (error) {
      setError(error?.message || error?.data?.message || 'Something went wrong')
      onError && onError(error)
    }
  }

  return (
    <Form onFinish={handleSubmit}>
      {error && <Alert type='error' message={error} className='mb-3' />}

      <Form.Item
        name='password'
        rules={[
          { required: true, message: 'Please input your password!' },
          { min: 5, message: 'Password needs to be at least 5 characters' },
        ]}
        hasFeedback
      >
        <Input.Password placeholder='Enter new password' size='large' />
      </Form.Item>

      <Form.Item
        name='confirm'
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject('The two passwords that you entered do not match!')
            },
          }),
        ]}
      >
        <Input.Password placeholder='Confirm Password' size='large' />
      </Form.Item>
      <Form.Item>
        <Button htmlType='submit' size='large' type='primary' block={true} loading={isResetting}>
          Save new password
        </Button>
      </Form.Item>
    </Form>
  )
}
