import { documintApi } from '.'
import _ from 'lodash'

export const templateApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    // RETRIEVE
    getTemplate: build.query({
      query: (id) => `/templates/${id}`,
      // providesTags: (result, error, id) => [{ type: 'Templates', id }],
    }),

    // LIST
    getTemplates: build.query({
      query: ({ page, limit, select, sort = '-updatedAt', ...rest } = {}) => {
        const queryString = _.chain({ page, limit, select, sort, ...rest })
          .pickBy((item) => item !== undefined)
          .toPairs()
          .value()
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
        return `/templates?${queryString}`
      },
      providesTags: ({ data } = {}) =>
        data?.data
          ? [...data?.data?.map?.(({ id }) => ({ type: 'Templates', id })), { type: 'Templates', id: 'TEMPLATE_LIST' }]
          : [{ type: 'Templates', id: 'TEMPLATE_LIST' }],
    }),

    // CREATE
    createTemplate: build.mutation({
      query: (data) => {
        const fields = [
          'name',
          'options',
          'assets',
          'components',
          'styles',
          'html',
          'css',
          'headerTemplate',
          'footerTemplate',
          'fieldSchema',
          'documentNamePattern',
          'thumbnail',
          'testData',
        ]
        const cleanData = _.pick(data, fields)
        return { url: `/templates`, method: 'POST', body: cleanData }
      },
      providesTags: (result, error, id) => [{ type: 'Templates', id }],
      invalidatesTags: (result, error, { id }) => [{ type: 'Templates', id: 'TEMPLATE_LIST' }],
    }),

    // UPDATE
    updateTemplate: build.mutation({
      query: ({ id, ...patch }) => ({ url: `/templates/${id}`, method: 'PUT', body: patch }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          documintApi.util.updateQueryData('getTemplate', id, (draft) => Object.assign(draft, patch))
        )

        queryFulfilled
          .then(({ data }) => {
            const { __v } = data
            dispatch(documintApi.util.updateQueryData('getTemplate', id, (draft) => Object.assign(draft, { __v })))
          })
          .catch(patchResult.undo)
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Templates', id },
        { type: 'Templates', id: 'TEMPLATE_LIST' },
      ],
    }),

    // DELETE
    deleteTemplate: build.mutation({
      query: (id) => ({ url: `/templates/${id}`, method: 'DELETE' }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Templates', id },
        { type: 'Templates', id: 'TEMPLATE_LIST' },
      ],
    }),

    // CLONE
    cloneTemplate: build.mutation({
      query: ({ id, ...rest }) => ({ url: `/templates/${id}/clone`, method: 'POST', body: {} }),
      providesTags: (result, error, id) => [{ type: 'Templates', id: result.id }],
      invalidatesTags: (result, error, { id }) => [{ type: 'Templates', id: 'TEMPLATE_LIST' }],
    }),

    // LIST GALLERY
    getGalleryTemplates: build.query({
      query: ({ page, limit, select, sort = 'order', ...rest } = {}) => {
        const queryString = _.chain({ page, limit, select, sort, ...rest })
          .pickBy((item) => item !== undefined)
          .toPairs()
          .value()
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
        return `/templates/gallery?${queryString}`
      },
      providesTags: ({ data } = {}) => {
        return data
          ? [...data.map(({ id }) => ({ type: 'Template', id })), { type: 'Template', id: 'GALLERY_LIST' }]
          : [{ type: 'Template', id: 'GALLERY_LIST' }]
      },
    }),
  }),
})

export const {
  useGetTemplateQuery,
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useCloneTemplateMutation,
  useGetGalleryTemplatesQuery,
} = templateApi

export default templateApi
