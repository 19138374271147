import React from 'react'
import { Modal, notification, Typography } from 'antd'
import isFunction from 'lodash/isFunction'
import ApiAppForm from './ApiAppForm'
import { useGetApiAppsQuery, useUpdateApiAppMutation } from '@documintApi/api-apps'

export default function EditApiAppModal({ id, open, onFinish, onCancel }) {
  const { apiApp = {} } = useGetApiAppsQuery(undefined, {
    selectFromResult: ({ data }) => ({ apiApp: data?.data?.find?.((apiApp) => apiApp.id === id) }),
  })
  const [update, { isLoading }] = useUpdateApiAppMutation()

  const handleSave = async (values) => {
    try {
      const { error } = await update({ id, ...values })
      if (error) {
        notification.error({ message: 'Something went wrong', description: error?.data?.message })
      } else if (isFunction(onFinish)) {
        onFinish(values)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => isFunction(onCancel) && onCancel()

  return (
    <Modal
      title={
        <>
          Rename Api Key starting with <Typography.Text code>{apiApp?.prefix}</Typography.Text>
        </>
      }
      open={open}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      <ApiAppForm apiApp={apiApp} onSave={handleSave} onCancel={handleCancel} isSaving={isLoading} />
    </Modal>
  )
}
