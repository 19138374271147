import React from 'react'
import { Modal, Typography } from 'antd'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import isFunction from 'lodash/isFunction'

import ApiKeyForm from './ApiKeyForm'

const { Title } = Typography

const IntegrationModal = NiceModal.create(({ title, type, onSave, isSaving, field = {}, ...rest }) => {
  const modal = useModal()

  const handleSave = async (values) => {
    isFunction(onSave) && (await onSave(values))
    modal.hide()
    modal.resolve(values)
  }

  return (
    <Modal
      open={modal.visible}
      onCancel={() => modal.hide()}
      afterClose={(visible) => {
        !visible && modal.remove()
      }}
      footer={false}
    >
      {title && (
        <Title level={3} style={{ textAlign: 'center' }}>
          {title}
        </Title>
      )}
      {type === 'api-key' && <ApiKeyForm title={title} {...field} onSave={handleSave} />}
    </Modal>
  )
})

export default IntegrationModal
