import { documintApi } from '.'

export const accountApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getAccount: build.query({
      query: () => `/account?populate=company.logo`,
      providesTags: ['Account'],
    }),

    updateAccount: build.mutation({
      query: ({ id, ...patch }) => ({ url: `/account`, method: 'PUT', body: patch }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          documintApi.util.updateQueryData('getAccount', id, (draft) => Object.assign(draft, patch))
        )

        queryFulfilled
          .then(({ data }) => {
            const { __v } = data
            dispatch(documintApi.util.updateQueryData('getAccount', id, (draft) => Object.assign(draft, { __v })))
          })
          .catch(patchResult.undo)
      },
      invalidatesTags: ['Account'],
    }),
  }),
})

export const { useGetAccountQuery, useUpdateAccountMutation } = accountApi

export default accountApi
