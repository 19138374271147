import { documintApi } from '.'

export const api = documintApi.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation({
      query: ({ captcha, ...user }) => {
        const config = { method: 'POST', url: `/auth/register`, body: user, headers: {} }
        if (captcha) config.headers['g-recaptcha-response'] = captcha
        return config
      },
    }),

    login: build.mutation({
      query: ({ email, password, captcha }) => {
        const config = { method: 'POST', url: '/auth/session', body: { email, password }, headers: {} }
        if (captcha) config.headers['g-recaptcha-response'] = captcha
        return config
      },
    }),

    logout: build.mutation({
      query: () => ({ method: 'DELETE', url: '/auth/session' }),
      invalidatesTags: ['Account', 'ApiApps', 'Profile', 'Subscription', 'Templates', 'Templates'],
    }),

    recoverPassword: build.mutation({
      query: ({ email }) => ({ method: 'POST', url: '/auth/recover', body: { email } }),
    }),

    validatePasswordResetToken: build.query({
      query: (token) => `/auth/reset-password/${token}`,
    }),

    resetPassword: build.mutation({
      query: ({ password, token }) => ({ method: 'POST', url: `/auth/reset-password/${token}`, body: { password } }),
    }),

    validateSession: build.query({
      query: () => '/auth/session',
    }),
  }),
})

export const {
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useRecoverPasswordMutation,
  useValidatePasswordResetTokenQuery,
  useResetPasswordMutation,
  useValidateSessionQuery,
  useLazyValidateSessionQuery,
} = api

export default api
