import axios from 'axios'

axios.defaults.withCredentials = true

const http = {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
  CancelToken: axios.CancelToken,
  isCancel: axios.isCancel,
}

export default http
