import React, { useState, useRef, useEffect } from 'react'
import { Form, Button, Alert } from 'antd'
import { Elements, AddressElement, useElements, CardElement } from '@stripe/react-stripe-js'
import { isFunction } from 'lodash'

import { stripePromise } from '@hooks/subscriptionHooks'
import { usePaymentMethodsContext } from '@features/subscription'
import { usePaymentMethods } from '@services/subscriptionService'
import SecuredCheckoutBadge from '../SecuredCheckoutBadge'

export default function PaymentMethodForm(props) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodsFormInner {...props} />
    </Elements>
  )
}

PaymentMethodForm.defaultProps = {
  onSuccess: () => {},
  onChange: () => {},
  submitText: 'Add Card',
}

function PaymentMethodsFormInner({ onSuccess, onChange, submitButton, submitText, form, ...rest }) {
  const [formRef] = Form.useForm()
  const ref = useRef(form || formRef)

  const elements = useElements()
  const { create: createPaymentMethod } = usePaymentMethods()
  const { attach, isCreating, setIsCreating } = usePaymentMethodsContext()

  const [values, setValues] = useState({ complete: false, address: {} })
  const [error, setError] = useState(null)
  const [addressFormIsComplete, setAddressFormIsComplete] = useState(false)
  const [cardFormIsComplete, setCardFormIsComplete] = useState(false)
  const formsAreComplete = addressFormIsComplete && cardFormIsComplete

  /**
   * Handles Form Submit
   */
  async function handleFinish() {
    try {
      setIsCreating(true)
      setError(null)

      const addressElement = elements.getElement(AddressElement)
      const address = await addressElement.getValue()
      if (!address.complete) throw new Error('Your billing address is incomplete.')

      const cardElement = elements.getElement(CardElement)
      const { error: createPmError, paymentMethod } = await createPaymentMethod(cardElement, address.values)
      if (createPmError) throw new Error(createPmError.message)

      const { data, error: attachmentError } = await attach(paymentMethod.id, { isDefault: true })
      if (attachmentError) throw new Error(attachmentError?.data?.message)

      if (isFunction(onSuccess)) onSuccess(data)
    } catch (error) {
      setError(error?.message || error)
    } finally {
      setIsCreating(false)
    }
  }

  useEffect(() => {
    if (isFunction(onChange)) onChange(values)
  }, [onChange, values])

  const handleAddressElementChange = ({ complete, value }) => {
    setAddressFormIsComplete(complete)
    setValues((prev) => ({ ...prev, address: value, complete: cardFormIsComplete && complete }))
  }

  const handleCardElementChange = ({ complete, value, ...rest }) => {
    setCardFormIsComplete(complete)
    setValues((prev) => ({ ...prev, card: { ...value, ...rest }, complete: addressFormIsComplete && complete }))
  }

  const submitButtonElement =
    submitButton !== undefined ? (
      submitButton
    ) : (
      <Button size='large' type='primary' htmlType='submit' disabled={error || !formsAreComplete} loading={isCreating}>
        {submitText}
      </Button>
    )

  return (
    <Form onFinish={handleFinish} className='d-flex flex-column' form={ref.current} {...rest}>
      {error && <Alert type='error' message={error} style={{ marginBottom: '1em' }} />}

      <AddressElement options={{ mode: 'billing' }} onChange={handleAddressElementChange} />

      <br />

      <div className='ant-input ant-input-lg' style={{ padding: '10px' }}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                  color: '#aab7c4',
                },
                padding: '15px',
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
          onChange={handleCardElementChange}
        />
      </div>

      <SecuredCheckoutBadge style={{ textAlign: 'center', marginTop: '2em' }} />

      {submitButtonElement && submitButtonElement}
    </Form>
  )
}
