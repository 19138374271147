import { isRejectedWithValue } from '@reduxjs/toolkit'
import guidingService from '@services/guiding-service'
import hubspotService from '@services/hubspot-service'

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // notification.error({ title: 'Async error!', message: action.error.data.message })
  }

  return next(action)
}

export const identifyUser = (api) => (next) => async (action) => {
  if (action.type === 'auth/setProfile') {
    guidingService.identify(action.payload)
    hubspotService.identify(action.payload)
  }
  return next(action)
}
