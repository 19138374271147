import React from 'react'

import { Button, Typography } from 'antd'
import { usePaymentMethodsContext } from '@features/subscription'
import PaymentMethodForm from './PaymentMethodForm'
import PaymentMethodList from './PaymentMethodList'
import { useToggle } from '@hooks/helperHooks'

const { Title } = Typography

export default function PaymentMethods() {
  const { hasPaymentMethods } = usePaymentMethodsContext()
  const [showForm, toggleForm] = useToggle(!hasPaymentMethods)

  const handleAddPaymentMethod = () => {
    toggleForm(false)
  }

  return (
    <div>
      <div className='mb-2'>
        {hasPaymentMethods && (
          <>
            <Title level={4}>Payment Methods</Title>
            <PaymentMethodList />
          </>
        )}
      </div>
      <div className='d-flex flex-column justify-center'>
        {showForm ? (
          <>
            <Title level={4}>Add Payment Method</Title>
            <PaymentMethodForm onSuccess={handleAddPaymentMethod} />
          </>
        ) : (
          <Button onClick={toggleForm} type='link'>
            Add {hasPaymentMethods && 'another '}payment method
          </Button>
        )}
      </div>
    </div>
  )
}
