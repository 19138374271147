import React, { useEffect } from 'react'
import { useBreakpoint } from '../../../hooks/helperHooks'
import Grid from '../../../components/Grid'
import TemplateGridListItem from './TemplateGridListItem'
import { useTemplatesContext } from '../context/TemplateContext'
import useActionMenuItems from '../hooks/actionMenuItemHooks'

export const GRID = { gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }

export default function TemplateGridList(props) {
  const { title, itemActions, grid, ...rest } = props
  const templatesContext = useTemplatesContext()
  const { templates, pagination, open, isFetching } = templatesContext
  const renderActionMenuItems = useActionMenuItems()
  const breakpoint = useBreakpoint()
  const pageSizeOptions = getPageSizeOptions(GRID, breakpoint)

  useEffect(() => {
    if (pagination && pageSizeOptions[1] !== pagination.pageSize) {
      pagination.onShowSizeChange && pagination.onShowSizeChange(pageSizeOptions[1])
    }
  }, [breakpoint, pagination, pageSizeOptions])

  function renderItem(template) {
    const actionMenuItems = renderActionMenuItems(template)
    return <TemplateGridListItem template={template} actions={actionMenuItems} onClick={() => open(template)} />
  }

  return (
    <Grid
      items={templates}
      renderItem={renderItem}
      loading={isFetching}
      grid={grid}
      pagination={{
        ...pagination,
        onChange: pagination.setCurrentPage,
        onShowSizeChange: (page, size) => pagination.setPageSize(size),
      }}
      {...rest}
    />
  )
}

TemplateGridList.defaultProps = {
  grid: GRID,
}

function getPageSizeOptions(grid, breakpoint) {
  const cardsPerRow = grid[breakpoint]
  return Array.apply(null, Array(5)).map((v, i) => cardsPerRow * 2 * (i + 1))
}
