import React from 'react'
import { Card, Typography, Form, Alert, Input, Button } from 'antd'
import { LockFilled } from '@ant-design/icons'

const { Title } = Typography

export default function PasswordPrompt({ onSubmit, error }) {
  return (
    <div className='d-flex flex-column h-100 justify-content-center'>
      <Card
        title={
          <Title level={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='py-2'>
            <LockFilled className='text--secondary' style={{ fontSize: '1.6em', marginRight: '.5em' }} /> Document is protected
          </Title>
        }
        style={{ minWidth: '400px' }}
        className='px-4 py-2'
      >
        <Form onFinish={onSubmit} layout='vertical'>
          {error && <Alert type='error' message={error} className='mb-3' />}
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password size='large' placeholder='Enter password...' />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type='primary' htmlType='submit' size='large'>
              View Document
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}
