export const DEFAULT_PROFILE = {
  name: {
    givenName: '',
    familyName: '',
  },
  email: '',
  preferences: {
    emailOptIn: false,
  },
}
