import React from 'react'
import { Result, Button } from 'antd'
import FixedHeaderLayout from '../../layouts/FixedHeaderLayout'

export default function NotFound() {
  return (
    <FixedHeaderLayout className='d-flex justify-content-center'>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={<Button type='primary' href="/">Back Home</Button>}
      />
    </FixedHeaderLayout>
  )
}
