import React from 'react'
import { List, Button, Popconfirm, message } from 'antd'
import { startCase } from 'lodash'

import FaIcon from '@components/icons/FaIcon'
import { usePaymentMethodsContext } from '../../context/PaymentMethodsContext'

export default function PaymentMethodList({ ...props }) {
  const { paymentMethods, setDefault, delete: del } = usePaymentMethodsContext()

  /**
   * Sets default payment method
   * @param {String} paymentMethodId
   */
  const handleSetDefault = async (paymentMethodId) => {
    const { error } = await setDefault(paymentMethodId)
    if (!error) return
    message.error('Unable to update payment method.')
  }

  const handleDelete = async (stripePaymentMethodId) => {
    const { error } = await del(stripePaymentMethodId)
    if (!error) return
    message.error('Unable to delete payment method.')
  }

  function renderListItem({ stripePaymentMethodId, card, isDefault, ...rest }) {
    const { brand, last4, exp_month, exp_year } = card

    const title = (
      <>
        {startCase(brand)} card ending in <b>{last4}</b>{' '}
      </>
    )

    const description = `Expires ${exp_month}/${exp_year}`

    const actions = [
      <Button size='small' disabled={isDefault} type='link' onClick={() => handleSetDefault(stripePaymentMethodId)}>
        {isDefault ? 'Default' : 'Set default'}
      </Button>,
      <Popconfirm
        title='Are you sure you want to delete this payment method?'
        onConfirm={() => handleDelete(stripePaymentMethodId)}
        okText='Delete'
        okButtonProps={{ danger: true }}
        disabled={isDefault}
      >
        <Button
          size='small'
          type='text'
          icon={<FaIcon icon='faTrash' />}
          danger
          title='Delete payment method'
          disabled={isDefault}
        />
      </Popconfirm>,
    ]
    return (
      <List.Item style={{ overflow: 'hidden' }} actions={actions}>
        <List.Item.Meta title={title} description={description} />
      </List.Item>
    )
  }

  return <List dataSource={paymentMethods} renderItem={renderListItem} style={{ width: '100%' }} />
}
