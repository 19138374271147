import React from 'react'
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import PrivateRoute from './components/common/PrivateRoute'
import Templates from './pages/templates'
import TemplatesTrash from './pages/templates/trash'
import Profile from './pages/settings/profile'
import Account from './pages/settings/account'
import Subscription from './pages/settings/subscription'
import Integrations from './pages/settings/integrations'
import Template from './components/Templates/Template'
import NotFound from './components/system/NotFound'
import Login from './pages/auth/login'
import Register from './pages/auth/register'
import RecoverPassword from './pages/auth/recover-password'
import ResetPassword from './pages/auth/reset-password'
import Assets from './pages/assets'
import Checkout from './pages/checkout'
import Airtable from './components/integrations/Airtable'
import Document from './components/Documents/Document'
import DashboardLayout from './layouts/DashboardLayout'
import NiceModal from '@ebay/nice-modal-react'
import UserFeedbackModal from '@features/userFeedback/components/UserFeedbackModal'

// const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG ? JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG) : null
// if (firebaseConfig) {
//   const app = initializeApp(firebaseConfig);
//   const analytics = getAnalytics(app);
//   // TODO: add analytics object to be accessed via Context
// }

export default function App() {
  return (
    <NiceModal.Provider>
      <Router>
        <Switch>
          <Route path='/not-found' exact component={NotFound} />
          <Route path='/login' exact component={Login} />
          <Route path='/register' exact component={Register} />
          <Route path='/auth/recover-password' exact component={RecoverPassword} />
          <Route path='/auth/reset-password/:token' exact component={ResetPassword} />
          <Route path='/share/documents/:id' exact component={Document} />
          <Route path='/integrations/airtable' exact component={Airtable} />

          <PrivateRoute path={['/templates', '/templates/trash', '/assets', '/settings*']} exact={true}>
            <DashboardLayout>
              <Switch>
                <PrivateRoute path='/templates' exact component={Templates} />
                <PrivateRoute path='/templates/trash' exact component={TemplatesTrash} />
                <PrivateRoute path='/assets' exact component={Assets} />
                <PrivateRoute path='/settings/profile' exact component={Profile} />
                <PrivateRoute path='/settings/account' exact component={Account} />
                <PrivateRoute path='/settings/subscription' exact component={Subscription} />
                <PrivateRoute path='/settings/integrations' exact component={Integrations} />
                <Redirect from='/settings*' to='/settings/profile' />
              </Switch>
            </DashboardLayout>
          </PrivateRoute>

          <PrivateRoute path='/checkout*' exact component={Checkout} />
          <PrivateRoute path='/templates/:id' exact component={Template} />

          <Redirect from='/' exact to='/templates' />
          <Redirect from='*' to='/not-found' />
        </Switch>
        <UserFeedbackModal />
      </Router>
    </NiceModal.Provider>
  )
}
