export const identify = (user) => {
  try {
    if (!user || !user.id || !window?._hsq) return
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['identify', { email: user.email, user_id: user.id }])
  } catch (err) {
    console.log(err)
  }
}

const defaultObj = { identify }
export default defaultObj
