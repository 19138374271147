import React, { createContext, useContext } from 'react'
import {
  useGetSubscriptionQuery,
  useCreatePortalPageMutation,
  useCancelScheduledUpdateMutation,
} from '@documintApi/subscription'

const SubscriptionContext = createContext()

export function useSubscriptionContext() {
  return useContext(SubscriptionContext)
}

export const SubscriptionProvider = ({ children }) => {
  const { data: subscription = {}, isLoading, isFetching, refetch, isUninitialized } = useGetSubscriptionQuery()

  const [createPortalPage, { data: portalPage, isLoading: portalPageIsLoading, error: portalPageError }] =
    useCreatePortalPageMutation()

  const [cancelSchedule, { isLoading: isCancelingSchedule }] = useCancelScheduledUpdateMutation()

  const value = {
    subscription,
    isLoading,
    isFetching,
    refetch,
    isUninitialized,
    cancelSchedule,
    isCancelingSchedule,
    portalPage: {
      create: createPortalPage,
      isLoading: portalPageIsLoading,
      error: portalPageError,
      url: portalPage,
    },
  }

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>
}

const context = { SubscriptionProvider, useSubscriptionContext }

export default context
