import React from 'react'
import { Alert, Button, Typography, Popconfirm } from 'antd'
import { isDate, isNil } from 'lodash'
import { format as dateFormat } from 'date-fns'

import { useSubscriptionContext } from '@features/subscription'

const { Text } = Typography

export default function SubscriptionAlerts() {
  const { subscription, isLoading, cancelSchedule, isCancelingSchedule } = useSubscriptionContext()

  const { nextPlan, nextPlanStartDate, latestInvoice } = subscription

  const alerts = []

  const scheduledUpdate = {
    type: 'info',
    message: (
      <Text>
        Your account will switch to the <b>{nextPlan && nextPlan.name} Plan</b> on{' '}
        <b>{formatDate(nextPlanStartDate)}</b>
      </Text>
    ),
    action: (
      <Popconfirm
        title={
          <>
            Are you sure to <b>cancel this scheduled change</b> to your subscription?
          </>
        }
        onConfirm={cancelSchedule}
        okText='Cancel change'
        cancelText='Nevermind'
        okButtonProps={{ loading: isCancelingSchedule }}
      >
        <Button type='link' size='small'>
          cancel scheduled change
        </Button>
      </Popconfirm>
    ),
  }

  const paymentPastDue = {
    type: 'warning',
    message: <>Your payment failed. Please update your payment method to avoid interruptions in your service.</>,
    action: (
      <a href={latestInvoice?.paymentLink} alt='Payment Link' targe='_blank' rel='noopener noreferrer'>
        Add payment method
      </a>
    ),
  }

  if (!isNil(nextPlan)) alerts.push(scheduledUpdate)

  if (subscription.status === 'pastDue') alerts.push(paymentPastDue)

  if (isLoading || alerts.length <= 0) return <></>

  return alerts.map(({ ...props }, index) => (
    <Alert key={index} {...props} banner={false} showIcon={true} style={{ width: '100%', marginBottom: '1em' }} />
  ))
}

function formatDate(date, format = 'MM/dd/yyyy') {
  if (!date) return
  const dateObj = isDate(date) ? date : new Date(date)
  return dateFormat(dateObj, format)
}
