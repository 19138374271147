import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../services/documintApi/auth'

const initialState = {
  profile: null,
  account: null,
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProfile(state, action) {
      state.profile = action.payload
    },
    setAccount(state, action) {
      state.account = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.account = payload.account
      state.profile = payload.user
    })
  },
})

export const { setProfile, setAccount } = slice.actions

export default slice.reducer

export const selectProfile = (state) => state.auth.profile
export const selectAccount = (state) => state.auth.account
