import React, { useState } from 'react'
import { Modal, Typography, Alert, message, notification } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CopyOutlined } from '@ant-design/icons'
import isFunction from 'lodash/isFunction'
import ApiAppForm from './ApiAppForm'
import { useAddApiAppMutation } from '@documintApi/api-apps'

const { Paragraph } = Typography

const DEFAULT_VALUE = {}

export default function NewApiAppModal({ open, onCancel, onFinish }) {
  const [add, { isLoading }] = useAddApiAppMutation()
  const [apiApp, setApiApp] = useState(DEFAULT_VALUE)

  const handleAdd = async (values) => {
    const { data, error } = await add(values)
    if (error) {
      notification.error({ message: 'Something went wrong' })
      isFunction(onFinish) && onFinish()
    } else {
      setApiApp(data)
    }
  }

  const handleFinish = () => {
    message.success('Copied to clipboard')
    setApiApp(null)
    isFunction(onFinish) && onFinish()
  }

  const handleCancel = () => {
    setApiApp(null)
    isFunction(onCancel) && onCancel()
  }

  const steps = {
    1: (
      <>
        <Paragraph>Hint: Use the name of the app or service that you're connecting to Documint</Paragraph>
        <ApiAppForm onSave={handleAdd} isSaving={isLoading} />
      </>
    ),
    2: (
      <>
        <Paragraph className='mb-3'>
          Copy the key below and store it in a safe place. You will not be able to access it after you close this box.
        </Paragraph>

        <Alert
          type='info'
          id='apiKeyDisplay'
          message={
            <CopyToClipboard text={apiApp?.key} onCopy={handleFinish}>
              <div className='d-flex justify-content-between align-items-center'>
                {apiApp?.key}
                <CopyOutlined />
              </div>
            </CopyToClipboard>
          }
        />
      </>
    ),
  }

  const step = apiApp?.key === undefined ? steps[1] : steps[2]

  return (
    <Modal title='Create a new Api Key' open={open} footer={null} onCancel={handleCancel} destroyOnClose={true}>
      {step}
    </Modal>
  )
}
