import React from 'react'
import { Typography } from 'antd'
import FaIcon from '@components/icons/FaIcon'

const { Title, Paragraph } = Typography

export default function SecuredCheckoutBadge({ showPrivacy = false, ...rest }) {
  return (
    <div {...rest}>
      <Title level={5} style={{ marginBottom: '.5em' }}>
        Secured by
        <img
          src='https://cdn.brandfolder.io/KGT2DTA4/at/frwcjft3nmmn6fccbt79bcm8/Stripe_wordmark_-_slate.svg'
          alt='Secured by Stripe'
          style={{ maxWidth: '70px' }}
        />
        <FaIcon icon='faShieldCheck' />
      </Title>

      {showPrivacy && (
        <div style={{ textAlign: 'center' }}>
          <Paragraph style={{ fontSize: '.8em' }}>
            <img src='/images/icons/portal-lock.svg' alt='' width='10px' style={{ margin: '-3px .5em 0 0' }} />
            This is a secure 128-bit SSL encrypted payment
          </Paragraph>
          <Paragraph style={{ fontSize: '.8em' }}>
            We guarantee 100% privacy. Your information will not be shared.
          </Paragraph>
        </div>
      )}
    </div>
  )
}
