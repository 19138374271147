import React from 'react'
import { useParams } from 'react-router-dom'

import MainLayout from './../../../layouts/MainLayout'
import { SubscriptionProvider } from '@features/subscription'
import { DesignerProvider } from '../../../contexts/DesignerContext'
import { PreviewerProvider } from './Previewer/context/PreviewerContext'
import Loading from '../../common/Loading'

const Content = React.lazy(() => import('./Content'))

export default function Template({ children }) {
  const { id } = useParams()

  return (
    <SubscriptionProvider>
      <DesignerProvider id={id}>
        <PreviewerProvider>
          <MainLayout>
            <React.Suspense fallback={<Loading />}>
              <Content />
            </React.Suspense>
          </MainLayout>
        </PreviewerProvider>
      </DesignerProvider>
    </SubscriptionProvider>
  )
}
