import React from 'react'
import isFunction from 'lodash/isFunction'
import { Modal, Typography, Input, message, Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { copy } from 'copy-to-clipboard'

const { Paragraph } = Typography

export default function ShareModal({ visible, url, onClose, ...rest }) {
  const handleCopyUrl = () => {
    copy(url)
    message.info({ content: 'Copied to clipboard' })
  }

  const handleOk = () => {
    if (isFunction(onClose)) onClose()
  }

  const handleCancel = () => {
    if (isFunction(onClose)) onClose()
  }

  return (
    <Modal
      title='Shared Link'
      open={visible}
      cancelText='Close'
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      {...rest}
    >
      <Paragraph>Use the link below to share this document</Paragraph>
      <div style={{ display: 'flex' }} onClick={handleCopyUrl}>
        <Input value={url} style={{ marginRight: '5px' }} /> <Button icon={<CopyOutlined />} />
      </div>
    </Modal>
  )
}
