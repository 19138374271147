import React from 'react'

import { Skeleton, Row, Col } from 'antd'
import { getViewportDimensions, convertUnit } from '../../utils'
import _ from 'lodash'

const style = {
  backgroundColor: '#fff',
  overflow: 'hidden',
  padding: '50px',
  width: '612px',
  height: '792px',
}

const paragraphSettings = { rows: 6, width: ['100%', '100%', '95%', '100%', '90%'] }
const shortParagraphSettings = { rows: 3, width: ['100%', '100%', '90%'] }

export default function LoadingDocument({ width, height, format, isLandscape, margin, count, wrapperProps }) {
  const { width: vpWidth, height: vpHeight } = getViewportDimensions({ width, height, format, isLandscape, ppi: 72 })

  const padding = _(margin)
    .mapKeys((value, key) => _.camelCase(`padding-${key}`))
    .mapValues((value, key) => convertUnit(value, { ppi: 72 }))
    .value()

  return (
    <Row justify='center' {...wrapperProps} style={{ padding: '10px 0' }}>
      <Col>
        {Array.from({ length: count }).map((key, index) => (
          <div
            className='d-flex flex-column document-loading paper text-center mb-3'
            style={{ ...style, width: vpWidth, height: vpHeight, ...padding }}
            key={index}
          >
            <div style={{ overflow: 'hidden' }}>
              <div className='d-flex flex-row mb-3'>
                <Skeleton.Image className='mr-3 mt-3' />
                <Skeleton active round={true} paragraph={shortParagraphSettings} />
              </div>

              <Skeleton active round={true} title={false} paragraph={paragraphSettings} className='mb-3' />

              <Skeleton active round={true} title={false} paragraph={shortParagraphSettings} />
            </div>
          </div>
        ))}
      </Col>
    </Row>
  )
}

LoadingDocument.defaultProps = { width: '612px', height: '792px', margin: {}, count: 3 }
