import React, { useState } from 'react'
import { Form, Rate, Input, Button, Alert, Result } from 'antd'
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons'
import { selectProfile } from '@features/auth/authSlice'
import { useSelector } from 'react-redux'
import { useCreateUserFeedbackMutation } from '../../../services/documintApi/users'

const UserFeedbackForm = () => {
  // const formRef = Form.useForm(ref)
  const [createUserFeedback] = useCreateUserFeedbackMutation()
  const profile = useSelector(selectProfile)
  const baseStyle = { fontSize: '1.7em', opacity: 0.3 }
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState(null)
  const [feedback, setFeedback] = useState(null)

  const customIcons = {
    1: (style = {}) => (
      <FrownOutlined size='large' style={{ ...baseStyle, color: '#DD5434', ...style }} title='Very Disappointed' />
    ),
    2: (style = {}) => <FrownOutlined style={{ ...baseStyle, color: '#E7933D', ...style }} title='Disappointed' />,
    3: (style = {}) => <MehOutlined style={{ ...baseStyle, color: '#F6CD47', ...style }} title='neural' />,
    4: (style = {}) => <SmileOutlined style={{ ...baseStyle, color: '#8AB954', ...style }} title='Satisfied' />,
    5: (style = {}) => <SmileOutlined style={{ ...baseStyle, color: '#428F4E', ...style }} title='Very Satisfied' />,
  }

  const handleSubmit = async (values) => {
    try {
      const { deviceVersion, language, userAgent, userAgentData } = window.navigator
      const data = {
        ...values,
        userId: profile.id,
        accountId: profile.account,
        metadata: JSON.stringify({
          ...window.location,
          deviceVersion,
          language,
          userAgent,
          userAgentData,
        }),
      }
      await createUserFeedback(data)
      setFeedback(data)
      setIsSubmitted(true)
    } catch (error) {
      setError(
        <>
          We encountered an error while submitting your feedback. Our team has been notified. In the meantime, please
          share your feedback with
          <a href='mailto:support@documint.me' target='_blank' rel='noreferrer'>
            support@documint.me
          </a>
          .
        </>
      )
    }
  }

  const initialValues = {
    name: `${profile.name.givenName} ${profile.name.familyName}`,
  }

  if (isSubmitted) {
    const extra = []

    if (feedback.satisfaction < 3) {
      extra.push(
        <Button type='primary' key='reset' onClick={() => setIsSubmitted(false)}>
          Share more feedback
        </Button>
      )
    } else {
      extra.push(
        <div style={{ marginTop: '15px' }}>
          <a href='https://www.capterra.com/reviews/215492/Documint?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge'>
            <img
              border='0'
              src='https://assets.capterra.com/badge/1b4f630d06c2a5851255aa4ffe7751e6.svg?v=2155376&p=215492'
              alt=''
              style={{ maxHeight: '50px' }}
            />
          </a>
          <p style={{ maxWidth: '250px', margin: '25px auto 0' }}>
            Would you be so kind and leave us a review on Capterra?
          </p>
        </div>
      )
    }

    return (
      <Result
        icon={<div style={{ fontSize: '120px', lineHeight: 1 }}>🙌</div>}
        title='Thank you for your feedback!'
        subTitle='Your feedback has been sent to the Documint team. '
        extra={extra}
      />
    )
  }

  return (
    <Form layout='vertical' onFinish={handleSubmit} initialValues={initialValues}>
      <Form.Item name='name' label='Your name' rules={[{ required: true, message: 'Your name is required' }]}>
        <Input />
      </Form.Item>

      <Form.Item
        name='satisfaction'
        label='How satisfied are you with Documint?'
        rules={[{ required: true, message: 'Satisfaction level is required' }]}
      >
        <Rate
          character={({ index, value }) =>
            value === index + 1 ? customIcons[index + 1]({ opacity: 1 }) : customIcons[index + 1]()
          }
        />
      </Form.Item>

      <Form.Item name='feedback-message' label='Feedback' rules={[{ required: true, message: 'Feedback is required' }]}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item>
        <Button htmlType='submit'>Send feedback</Button>
      </Form.Item>

      {error && <Alert type='error' message={error} />}
    </Form>
  )
}

export default UserFeedbackForm
