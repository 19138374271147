import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import FaIcon from '@components/icons/FaIcon'
import { faStars } from '@fortawesome/pro-solid-svg-icons'

export default function ChangePlanButton({ label, icon, ...rest }) {
  const history = useHistory()

  const btnProps = {
    ...{
      icon: icon === false ? undefined : <FaIcon icon={faStars} style={{ color: '#FFCF19' }} />,
      onClick: () => history.push('/checkout'),
    },
    ...rest,
  }

  return label === false ? <Button {...btnProps} /> : <Button {...btnProps}>{label}</Button>
}

ChangePlanButton.defaultProps = {
  label: 'Upgrade plan',
}
