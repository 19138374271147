import React, { createContext, useContext, useState, useCallback } from 'react'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import clone from 'lodash/clone'

import { useGetSubscriptionQuery, useChangePlanMutation } from '@documintApi/subscription'
import { useGetPlansQuery } from '@documintApi/plans'

const CheckoutContext = createContext()

export function useCheckoutContext() {
  return useContext(CheckoutContext)
}

export function CheckoutProvider({ children }) {
  const TOTAL_STEPS = 3
  const [_stepIndex, _setStepIndex] = useState(0)
  const [_selectedPlanId, _setSelectedPlanId] = useState(null)

  const { data: subscription = {}, isLoading: isLoadingSubscription } = useGetSubscriptionQuery()
  const { data: { data: plans = [] } = {}, isLoading: isLoadingPlans } = useGetPlansQuery()
  const { currentPlan, nextPlan, defaultPaymentMethod } = subscription

  const [changePlan, { isLoading: isChangingPlan, error: changePlanError }] = useChangePlanMutation()

  // Get plan list
  const allPlans = getCleanedPlanList(plans, currentPlan, nextPlan)

  const _setStep = (value) => {
    value >= 0 && value <= TOTAL_STEPS && _setStepIndex(value)
    if (value === 0) _setSelectedPlanId(null)
  }

  const selectedPlan = allPlans.find(({ id }) => id === _selectedPlanId)

  const setSelectedPlan = (plan) => {
    if (typeof plan === 'string') return _setSelectedPlanId(plan)
    if (plan?.id) return _setSelectedPlanId(plan.id)
  }

  const nextStep = () => _setStep(_stepIndex + 1)
  const prevStep = () => _setStep(_stepIndex - 1)
  const setStepIndex = (index) => {
    if (index >= _stepIndex) return
    _setStep(index)
  }

  const checkout = useCallback(async () => {
    if (!selectedPlan) throw new Error('Plan not selected')
    return changePlan(selectedPlan.id)
  }, [selectedPlan, changePlan])

  const value = {
    stepIndex: _stepIndex,
    nextStep,
    prevStep,
    setStepIndex,
    subscription,
    isLoadingSubscription,
    defaultPaymentMethod,
    plans: allPlans,
    currentPlan,
    nextPlan,
    selectedPlan,
    setSelectedPlan,
    isLoadingPlans,
    checkout,
    isCheckingOut: isChangingPlan,
    checkoutError: changePlanError,
  }

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>
}

function getCleanedPlanList(plans, currentPlan, nextPlan) {
  plans = clone(plans)

  if (isNil(currentPlan) === false) plans.push({ ...currentPlan, isCurrent: true })
  if (isNil(nextPlan) === false) plans.push({ ...nextPlan, isNext: true })

  // filter out inactive and non-current plans
  const filteredPlans = plans?.reduce?.((acc, cur) => {
    const isDupe = acc.find(({ id }) => id === cur.id) !== undefined
    if ((!cur?.isActive && cur?.id !== currentPlan?.id) || isDupe) return acc
    acc.push(cur)
    return acc
  }, [])

  // Sort plans by price
  return sortBy(filteredPlans, ['price'])
}

const checkoutContext = { useCheckoutContext, CheckoutProvider }

export default checkoutContext
