import React, { createContext, useContext, useReducer, useRef, useCallback, useState } from 'react'
import { useGetTemplatesQuery } from '@documintApi/templates'
import { pickBy, isNil, isEqual } from 'lodash'
import TemplateRenameModal from '../components/TemplateRenameModal'
import { useToggle } from '@wojtekmaj/react-hooks'
import { notification } from 'antd'
import {
  useCloneTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
} from '../../../services/documintApi/templates'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const TemplatesContext = createContext()

export function useTemplatesContext() {
  return useContext(TemplatesContext)
}

const QUERY_ACTIONS = {
  SET_QUERY: 'set-query',
  SET_CURRENT_PAGE: 'set-page-current',
  SET_PAGE_SIZE: 'set-page-size',
  SET_FILTER: 'set-filter',
  SET_FILTERS: 'set-filters',
  ADD_FILTER: 'add-filter',
  CLEAR_FILTERS: 'clear-filters',
  SET_SORT: 'set-sort',
}
const NON_FILTER_KEYS = ['page', 'limit']

function queryReducer(state, action = {}) {
  const { type, payload } = action

  switch (type) {
    case QUERY_ACTIONS.SET_CURRENT_PAGE: {
      if (typeof payload !== 'number' || payload < 1) return state
      const newState = { ...state, page: payload }
      return pickBy(newState, (v) => !isNil(v))
    }

    case QUERY_ACTIONS.SET_PAGE_SIZE: {
      if (typeof payload !== 'number' || payload < 1) return state
      const newState = { ...state, limit: payload }
      return pickBy(newState, (v) => !isNil(v))
    }

    case QUERY_ACTIONS.SET_FILTER: {
      const newState = { ...state, ...payload, page: 1 }
      return pickBy(newState, (v) => !isNil(v))
    }

    case QUERY_ACTIONS.SET_FILTERS: {
      const newState = { page: 1, limit: state?.limit, ...payload }
      return pickBy(newState, (v) => !isNil(v))
    }

    case QUERY_ACTIONS.CLEAR_FILTERS: {
      const newState = { page: 1, limit: state.limit }
      return pickBy(newState, (v) => !isNil(v))
    }

    case QUERY_ACTIONS.SET_SORT: {
      const newState = { page: 1, limit: state.limit, ...state, sort: payload }
      return pickBy(newState, (v) => !isNil(v))
    }

    default:
      return state
  }
}

export function TemplatesProvider({ children, query: baseQuery = {} }) {
  const [query, dispatchQuery] = useReducer(queryReducer, { page: 1, limit: 20, sort: '-updatedAt' })
  const [update] = useUpdateTemplateMutation()
  const [clone, { isLoading: isDuplicating }] = useCloneTemplateMutation()
  const [del] = useDeleteTemplateMutation()
  const history = useHistory()

  // Rerender if provided base query is updated
  const baseQueryRef = useRef(baseQuery)
  if (!isEqual(baseQueryRef.current, baseQuery)) {
    dispatchQuery({ type: QUERY_ACTIONS.CLEAR_FILTERS })
    baseQueryRef.current = baseQuery
  }

  // Query Templates
  const {
    data = {},
    isLoading,
    isFetching,
  } = useGetTemplatesQuery({
    isTrashed: false,
    select: 'name,thumbnail,isActive,options.isLandscape,isTrashed,updatedAt',
    ...query,
    ...baseQuery,
  })
  const { data: templates = [], totalCount } = data

  const setCurrentPage = useCallback(
    (value) => dispatchQuery({ type: QUERY_ACTIONS.SET_CURRENT_PAGE, payload: value }),
    []
  )
  const setPageSize = useCallback((value) => dispatchQuery({ type: QUERY_ACTIONS.SET_PAGE_SIZE, payload: value }), [])
  const setFilter = useCallback(
    (name, value) => dispatchQuery({ type: QUERY_ACTIONS.SET_FILTER, payload: { [name]: value } }),
    []
  )
  const clearFilters = useCallback(() => dispatchQuery({ type: QUERY_ACTIONS.CLEAR_FILTERS }), [])

  const setSort = useCallback((value) => dispatchQuery({ type: QUERY_ACTIONS.SET_SORT, payload: value }), [])

  const handleError = useCallback((error) => {
    notification.error({ message: 'Something went wrong', description: error.data.message })
  }, [])

  // template actions
  const [selectedTemplateId, setSelectedTemplateId] = useState()
  const selectedTemplate = templates.find(({ id }) => id === selectedTemplateId)
  const setSelectedTemplate = useCallback((template) => setSelectedTemplateId(template.id), [])

  /**
   * Navigate to template
   * @param {Template|String} template
   */
  const handleOpenTemplate = useCallback(
    (template) => {
      const id = template?.id || template
      history.push(`/templates/${id}`)
    },
    [history]
  )

  const [isRenameModalOpen, toggleRenameModal] = useToggle(false)

  const handleRename = useCallback(
    async ({ id }) => {
      setSelectedTemplateId(id)
      toggleRenameModal(true)
    },
    [toggleRenameModal]
  )

  const doRename = async ({ id, name }) => {
    const { error } = await update({ id, name })
    if (error) handleError(error)
    toggleRenameModal(false)
  }

  const handleCancelRename = useCallback(() => {
    toggleRenameModal(false)
    setSelectedTemplateId(null)
  }, [toggleRenameModal])

  const handleToggleIsActive = useCallback(
    async ({ id, ...template }) => {
      const { error } = await update({ id, isActive: !template.isActive })
      if (error) handleError(error)
    },
    [update, handleError]
  )

  const handleDuplicate = useCallback(
    async ({ id }) => {
      const { error } = await clone({ id })
      if (error) handleError(error)
    },
    [clone, handleError]
  )

  const handleToggleTrashed = useCallback(
    async ({ id, isTrashed }) => {
      const { error } = await update({ id, isTrashed: !isTrashed, isActive: false })
      if (error) handleError(error)
    },
    [update, handleError]
  )

  const handleDelete = useCallback(
    async ({ id }) => {
      const { error } = await del(id)
      if (error) handleError(error)
    },
    [del, handleError]
  )

  const value = React.useMemo(
    () => ({
      templates,
      pagination: { current: query.page, pageSize: query.limit, total: totalCount, setCurrentPage, setPageSize },
      isLoading,
      isFetching,
      filters: pickBy(query, (value, key) => NON_FILTER_KEYS.includes(key) !== true),
      setFilter,
      sort: query.sort,
      setSort,
      clearFilters,
      selectedTemplate,
      setSelectedTemplate,
      open: handleOpenTemplate,
      rename: handleRename,
      toggleActive: handleToggleIsActive,
      duplicate: handleDuplicate,
      isDuplicating,
      toggleTrashed: handleToggleTrashed,
      del: handleDelete,
    }),
    [
      templates,
      isLoading,
      isFetching,
      query,
      totalCount,
      setCurrentPage,
      setPageSize,
      setFilter,
      clearFilters,
      setSort,
      selectedTemplate,
      setSelectedTemplate,
      handleOpenTemplate,
      handleRename,
      handleToggleIsActive,
      handleDuplicate,
      isDuplicating,
      handleToggleTrashed,
      handleDelete,
    ]
  )

  return (
    <TemplatesContext.Provider value={value}>
      {children}
      <TemplateRenameModal
        template={selectedTemplate}
        open={isRenameModalOpen}
        onOk={doRename}
        onCancel={handleCancelRename}
      />
    </TemplatesContext.Provider>
  )
}

const templateContext = { TemplatesProvider, useTemplatesContext }

export default templateContext
