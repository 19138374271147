import React from 'react'
import { Input } from 'antd'
import { useTemplatesContext } from '../context/TemplateContext'

export default function TemplatesSearch() {
  const { setFilter } = useTemplatesContext()

  const onSearch = (searchString) => {
    if (searchString === '') searchString = undefined
    setFilter('search', searchString)
  }

  return (
    <Input.Search
      placeholder='Search...'
      onSearch={onSearch}
      allowClear={true}
      style={{
        width: 200,
      }}
    />
  )
}
