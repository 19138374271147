import axios from 'axios'

axios.defaults.withCredentials = true

export const useHttp = () => {
  const get = axios.get
  const put = axios.put
  const post = axios.post
  const del = axios.delete
  const CancelToken = axios.CancelToken
  const isCancel = axios.isCancel

  return { get, put, post, del, CancelToken, isCancel }
}

const httpHooks = { useHttp }

export default httpHooks
