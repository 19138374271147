import React from 'react'
import { Select } from 'antd'

import { useTemplatesContext } from '../context/TemplateContext'

export default function TemplatesFilters({ disabled }) {
  const { setFilter, filters } = useTemplatesContext()

  const handleIsActiveFilterChange = (value) => {
    setFilter('isActive', value)
  }

  const activeOptions = [
    { label: 'All', value: null, key: 'all' },
    { label: 'Active', value: true, key: 'active' },
    { label: 'Inactive', value: false, key: 'inactive' },
  ]

  return (
    <Select
      name='Active'
      value={filters.isActive || null}
      dropdownMatchSelectWidth={false}
      options={activeOptions}
      onChange={handleIsActiveFilterChange}
      disabled={disabled}
    />
  )
}
TemplatesFilters.defaultProps = {
  disabled: false,
}
