import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Typography, Space, Card, Result } from 'antd'

import { ResetPasswordForm } from '@features/auth'

import MainLayout from '@Layouts/MainLayout'
import Loading from '../../components/common/Loading'
import { useValidatePasswordResetToken } from '../../features/auth/authHooks'

const { Title } = Typography

const STATES = {
  loading: 'loading',
  resetting: 'resetting',
  validToken: 'validToken',
  invalidToken: 'invalidToken',
  success: 'success',
  error: 'error',
}

export default function ResetPassword({ match: { params } }) {
  const [state, setState] = useState(STATES.loading)
  const { isValid, isValidating } = useValidatePasswordResetToken(params.token)

  async function handleSubmit() {
    setState(STATES.success)
  }

  useEffect(() => {
    if (isValidating) return
    setState(isValid ? STATES.validToken : STATES.invalidToken)
  }, [isValid, isValidating])

  return (
    <MainLayout className='bg-primary'>
      <div className='d-flex vh-100 align-items-center justify-content-center'>
        <Space direction='vertical' size='large' className='d-flex align-items-center'>
          <img src='/images/logo.svg' alt='Documint Logo' height='50px' />
          <Card style={{ minWidth: '450px' }}>{renderContent(state, { onSubmit: handleSubmit })}</Card>
        </Space>
      </div>
    </MainLayout>
  )
}

function renderContent(state, { onSubmit }) {
  switch (state) {
    case STATES.loading:
      return <Loading />
    case STATES.invalidToken:
      return (
        <Result
          status='warning'
          title='Token is invalid or expired'
          extra={[
            <Link to='/login' className='mr-3' key='1'>
              <Button>Login</Button>
            </Link>,
            <Link to='/auth/recover-password' key='2'>
              <Button type='primary'>Recover Password</Button>
            </Link>,
          ]}
        />
      )
    case STATES.success:
      return (
        <Result
          status='success'
          title='Password changed successfully'
          extra={[
            <Link to='/login' key='1'>
              <Button type='primary'>Login</Button>
            </Link>,
          ]}
        />
      )
    default:
      return (
        <div className='p-4'>
          <Title level={3} style={{ textAlign: 'center' }}>
            Enter new password
          </Title>
          <ResetPasswordForm onSubmit={onSubmit} />
        </div>
      )
  }
}
