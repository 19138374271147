import React from 'react'
import { Space, Input } from 'antd'
import { ZoomInOutlined, ZoomOutOutlined, ColumnHeightOutlined, ColumnWidthOutlined } from '@ant-design/icons'
import { isFunction } from 'lodash'
import Button from './Button'

const FIT_MODES = ['fit_page', 'fit_width']
export default function ScaleControls({
  scale,
  mode,
  onIncrement,
  onDecrement,
  onReset,
  onModeChange,
  buttonProps,
  orientation,
  controls,
  ...rest
}) {
  const handleIncrement = () => {
    if (isFunction(onIncrement)) onIncrement()
  }

  const handleDecrement = () => {
    if (isFunction(onDecrement)) onDecrement()
  }

  const handleToggleMode = () => {
    if (isFunction(onModeChange)) onModeChange()
  }
  const handleReset = ()=>{
    if(isFunction(onReset)) onReset()
  }
  const CONTROLS = {
    fit: (
      <Button
        key='toggle-fit'
        icon={mode === FIT_MODES[0] ? <ColumnWidthOutlined /> : <ColumnHeightOutlined />}
        onClick={handleToggleMode}
        tooltip={{ title: mode === FIT_MODES[0] ? 'Fit width' : 'Fit page', mouseEnterDelay: 0.5, placement: 'left' }}
        {...buttonProps}
      />
    ),
    scaleDown: (
      <Button
        key='scale-down'
        icon={<ZoomOutOutlined />}
        onClick={handleDecrement}
        {...buttonProps}
        tooltip={{ title: 'Zoom out', mouseEnterDelay: 0.5, placement: 'left' }}
      />
    ),
    value: (
      <Input
        key='scale'
        value={scale}
        suffix='%'
        // onBlur={handleScaleFinish}
        // onChange={handleScaleChange}
        className='scale-control__input'
        disabled
        onClick={handleReset}
        {...buttonProps}
      />
    ),
    scaleUp: (
      <Button
        key='scale-up'
        icon={<ZoomInOutlined />}
        onClick={handleIncrement}
        {...buttonProps}
        tooltip={{ title: 'Zoom in', mouseEnterDelay: 0.5, placement: 'left' }}
      />
    ),
    ' ': <span key='space'></span>,
  }

  return (
    <div className='scale-control' key='scale-controls'>
      <Space direction={orientation} align='center' {...rest}>
        {controls.map((i) => CONTROLS[i])}
      </Space>
    </div>
  )
}

ScaleControls.defaultProps = {
  buttonProps: {
    size: 'large',
  },
  orientation: 'horizontal',
  controls: ['fit', 'scaleUp', 'value', 'scaleDown'],
}
