import React, { useState } from 'react'
import { Modal, Space, Typography, Input } from 'antd'
import isFunction from 'lodash/isFunction'

import { CONFIRM_DELETE_TEXT } from '../consts'

const { Text } = Typography

export default function ConfirmDeleteAccountModal(props) {
  const { open, onConfirm, isUpdating, onCancel } = props
  const [canDelete, setCanDelete] = useState(false)

  function handleConfirmDeleteChange({ target: input }) {
    setCanDelete(() => input.value === CONFIRM_DELETE_TEXT)
  }

  async function handleDeleteAccount() {
    isFunction(onConfirm) && onConfirm()
  }

  return (
    <Modal
      open={open}
      title='Delete Account'
      okText='Delete Account'
      okButtonProps={{
        type: 'danger',
        disabled: !canDelete,
        title: canDelete ? 'This will permanently delete your account' : 'Enter "delete account" into the field above',
        loading: isUpdating,
      }}
      onOk={handleDeleteAccount}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Text>Are you sure you want to permanently delete your account?</Text>
        <Text type='danger'>
          This will delete your account including all of your templates and <b>CAN NOT</b> be undone.
        </Text>
        <Text>
          Enter <Text code>{CONFIRM_DELETE_TEXT}</Text> in the field below to confirm that you want to delete your
          account.
        </Text>
        <Input placeholder={CONFIRM_DELETE_TEXT} size='large' onChange={handleConfirmDeleteChange} />
      </Space>
    </Modal>
  )
}
