import React from 'react'
import { Result } from 'antd'
import Loading from './Loading'

export default function Display({ isVisible, isLoading, loader, error, children, resourceName, showErrorDetails }) {
  if (!isVisible) {
    return <></>
  } else if (error) {
    const errorStatus = error?.status || 500

    const DEFAULT_ERROR_PROPS = {
      status: 'warning',
      title: 'Something went wrong',
      subTitle: `Well this is embarrassing. We're not quite sure what happened but our developers have been notified.`,
    }

    const errorPropMap = {
      403: {
        status: '403',
        title: error?.statusText,
      },
      404: {
        status: '404',
        title: `We couldn't find the ${resourceName} you're looking for`,
      },
      500: {
        status: '500',
        title: 'Something went wrong',
      },
    }

    const errorProps = errorPropMap[errorStatus] ? errorPropMap[errorStatus] : DEFAULT_ERROR_PROPS

    if (showErrorDetails) {
      if (typeof error === 'string') {
        errorProps.subTitle = error
      } else if (error?.data?.message || error?.data?.statusText) {
        errorProps.subTitle = error.data?.message || error.data?.statusText
      } else {
        errorProps.subTitle = error?.message || error?.statusText
      }
      if (!errorProps.subTitle) errorProps.subTitle = DEFAULT_ERROR_PROPS.subTitle
    }

    return (
      <div className='d-flex h-100 flex-column justify-content-center'>
        <Result {...errorProps} />
      </div>
    )
  } else if (isLoading) {
    if (loader === false) return <></>
    return loader || <Loading minHeight='100vh' />
  } else {
    return children
  }
}
Display.defaultProps = {
  isVisible: true,
  resourceName: 'item',
  showErrorDetails: false,
}
