import React from 'react'
import { Form, Input, Button, Space } from 'antd'

export default function ApiAppForm({ apiApp, onSave, onCancel, isSaving }) {
  return (
    <Form onFinish={onSave} initialValues={apiApp}>
      <Form.Item name={['name']}>
        <Input placeholder='Api Key Name' />
      </Form.Item>
      <Form.Item>
        <Space direction='horizontal' size='small'>
          <Button onClick={onCancel}>Cancel</Button>
          <Button htmlType='submit' type='primary' loading={isSaving}>
            {apiApp?.id ? 'Save' : 'Create'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

ApiAppForm.defaultProps = {
  apiApp: {},
}
