import React, { useState, useEffect } from 'react'
import { Modal, Input, notification } from 'antd'
import { useUpdateTemplateMutation } from '../../../services/documintApi/templates'

export default function TemplateRenameModal(props) {
  const { template, open, onOk, onCancel } = props
  const [name, setName] = useState('')
  const [update, { isLoading }] = useUpdateTemplateMutation()

  useEffect(() => {
    setName(template.name)
  }, [template])

  const handleOk = async () => {
    const { error } = await update({ id: template.id, name })
    if (error) notification.error({ message: 'Something went wrong', description: error.data.message })
    if (onOk) onOk({ id: template.id, name }, template)
  }

  return (
    <Modal
      open={open}
      title='Rename template'
      onOk={handleOk}
      onCancel={onCancel}
      destroyOnClose={true}
      okText='Rename'
      okButtonProps={{ loading: isLoading }}
    >
      <Input value={name} onChange={(e) => setName(e.target.value)} />
    </Modal>
  )
}

TemplateRenameModal.defaultProps = {
  template: {},
}
