import React from 'react'

import { notification } from 'antd'
import sortBy from 'lodash/sortBy'

import { PlanCardList, PlanCard } from '@features/subscription'
import { useCheckoutContext } from '../../context/CheckoutContext'
import CheckoutHeader from './CheckoutHeader'
import * as Sentry from '@sentry/react'

export default function CheckoutStepSelectPlan({ onSelect }) {
  const {
    plans,
    currentPlan,
    nextPlan,
    isLoadingPlans,
    isLoadingSubscription,
    selectedPlan,
    setSelectedPlan,
    nextStep,
  } = useCheckoutContext()

  const handleSelect = async (plan) => {
    try {
      setSelectedPlan(plan.id)
      nextStep()
    } catch (error) {
      Sentry.captureException(error)
      notification.error({ message: 'Something went wrong', description: error.message })
    }
  }

  // filter out inactive and non-current plans
  const filteredPlans = plans?.reduce?.((acc, cur) => {
    const isDupe = acc.find(({ id }) => id === cur.id) !== undefined
    if ((!cur.isActive && cur.id !== currentPlan.id) || isDupe) return acc
    acc.push(cur)
    return acc
  }, [])

  // Sort plans by price
  const sortedPlans = sortBy(filteredPlans, ['price'])

  const renderItem = (plan = {}) => {
    const { id } = plan

    const isCurrent = id === currentPlan?.id
    const isNext = nextPlan !== undefined && id === nextPlan?.id

    const buttonProps = {}
    buttonProps.loading = selectedPlan?.id === id

    if (isNext || isCurrent) buttonProps.disabled = true
    const buttonText = isCurrent ? 'Active Plan' : isNext ? 'Upcoming Plan' : 'Select Plan'

    const isSelectable = !isCurrent && !isNext

    return (
      <PlanCard
        key={id}
        data={plan}
        onSelect={handleSelect}
        selectable={isSelectable}
        buttonProps={buttonProps}
        disabled={isCurrent || isNext}
        style={{ height: '100%', minWidth: '250px', maxWidth: '350px' }}
        buttonText={buttonText}
      />
    )
  }

  return (
    <>
      <CheckoutHeader title='Select a Plan' />
      <PlanCardList
        plans={sortedPlans}
        currentPlanId={currentPlan?.id}
        nextPlanId={nextPlan?.id}
        onSelect={handleSelect}
        isLoading={isLoadingPlans || isLoadingSubscription}
        renderItem={renderItem}
      />
    </>
  )
}
