import React from 'react'
import { Checkbox } from 'antd'
import { DEFAULT_PROFILE } from '../consts'
import DetailList from '@components/DetailList'

export default function ProfileDetails(props) {
  const { profile, isLoading } = props
  const { name, email, preferences } = profile

  const items = [
    { label: 'First Name', value: name.givenName },
    { label: 'Last Name', value: name.familyName },
    { label: 'Email', value: email },
    {
      label: 'Notify me about updates and promotions',
      value: <Checkbox checked={preferences?.emailOptIn} disabled={true} />,
    },
  ]

  return <DetailList items={items} isLoading={isLoading} />
}

ProfileDetails.defaultProps = {
  profile: DEFAULT_PROFILE,
}
