import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Space, Typography, Alert, Card, Result, Button, Divider } from 'antd'

import { RecoverPasswordForm } from '@features/auth'

import MainLayout from '@Layouts/MainLayout'

const { Title } = Typography

export default function RecoverPassword() {
  const [error, setError] = useState()
  const [email, setEmail] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const handleSubmit = async ({ email }) => {
    if (error) return setError(error)
    setEmail(email)
    setEmailSent(true)
  }

  function renderButtonLink({ url, imageUrl, name, key, ...rest }) {
    return (
      <a href={url} rel='noreferrer' target='_blank' className='mx-3' {...rest}>
        <Button
          icon={<img src={imageUrl} width='100%' height='auto' alt={name} />}
          style={{ padding: '3px', width: '47px', height: '47px' }}
          size='large'
        />
      </a>
    )
  }

  const mailClients = [
    {
      name: 'Apple Mail',
      url: 'https://www.icloud.com/mail',
      imageUrl: 'https://documint.s3.amazonaws.com/assets/images/logos/mail-macos-bigsur-logo.png',
    },
    {
      name: 'Gmail',
      url: 'https://www.gmail.com',
      imageUrl: 'https://documint.s3.amazonaws.com/assets/images/logos/gmail-logo.png',
    },
    {
      name: 'Outlook',
      url: 'https://outlook.com',
      imageUrl: 'https://documint.s3.amazonaws.com/assets/images/logos/microsoft-outlook-logo.png',
    },
  ]

  return (
    <MainLayout className='justify-content-center'>
      <Space size='large' direction='vertical' className='d-flex flex-column align-items-center'>
        {/* LOGO */}
        <Link to='/'>
          <img src='/images/logo.svg' alt='Documint Logo' height='50px' />
        </Link>

        <Card className='card--sm' style={{ minWidth: '450px' }}>
          <Space direction='vertical' size='large'>
            {/* FORM */}
            {emailSent ? (
              <Result
                status='success'
                title='Success!'
                subTitle={
                  <>
                    <br />
                    An email has been sent to <b>{email}</b>.
                    <br />
                    <br />
                    Check your inbox and follow the instructions in the email.
                  </>
                }
                style={{ padding: '0 .5em 0 .5em', margin: 0 }}
              >
                <div style={{ textAlign: 'center' }}>
                  <Divider className='mt-0 mb-3'>Open your mail client</Divider>
                  <div style={{ display: 'flex', justifyContent: 'space-around' }} className='pb-2'>
                    {mailClients.map((client, i) => renderButtonLink({ ...client, key: i }))}
                  </div>
                </div>
              </Result>
            ) : (
              <div className='d-flex flex-column align-items-center'>
                {/* TITLE */}
                <Title level={3} className='mb-3'>
                  Forgot your password?
                </Title>

                {/* Helper Text */}
                <p className='text--light text-center'>
                  Enter your email below and we'll email you a password reset link.
                </p>

                {/* Form */}
                <RecoverPasswordForm onSubmit={handleSubmit} style={{ width: '100%' }} />

                {/* Errors */}
                {error ? <Alert type='error' message={error} className='mb-2' /> : ''}
              </div>
            )}
          </Space>
        </Card>
      </Space>
    </MainLayout>
  )
}
