import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Space, Badge, Dropdown, Button, Tooltip } from 'antd'
import { formatDate } from '../../../utils'
import { useBreakpoint } from '../../../hooks/helperHooks'
import FaIcon from '../../../components/icons/FaIcon'
import { useTemplatesContext } from '../context/TemplateContext'
import useActionMenuItems from '../hooks/actionMenuItemHooks'

export const GRID = { gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }

export default function TemplateTableList(props) {
  const { title, extra, ...rest } = props
  const { templates, isFetching, pagination } = useTemplatesContext()
  const renderActionMenuItems = useActionMenuItems()
  const breakpoint = useBreakpoint()
  const pageSizeOptions = getPageSizeOptions(GRID, breakpoint)
  const history = useHistory()
  const goToTemplate = ({ id }) => history.push(`/templates/${id}`)

  useEffect(() => {
    if (pagination && pageSizeOptions[1] !== pagination.pageSize) {
      pagination.onShowSizeChange && pagination.onShowSizeChange(pageSizeOptions[1])
    }
  }, [breakpoint, pagination, pageSizeOptions])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, template) => (
        <Space size='large'>
          <Tooltip title={template.isActive ? 'Active' : 'Inactive'}>
            <Badge status={template.isActive ? 'processing' : 'default'} />
          </Tooltip>

          <Tooltip title={<img src={template.thumbnail.url} width='230px' alt={template.name} />} mouseEnterDelay={0.5}>
            <img src={template.thumbnail.url} width='50px' alt={template.name} />
          </Tooltip>
          {template.name}
        </Space>
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (_, template) => formatDate(template.updatedAt, 'M/dd/yyyy'),
    },
    {
      // title: 'Action',
      key: 'action',
      width: '50px',
      align: 'center',
      fixed: 'right',
      render: (_, template) => {
        return (
          <Dropdown trigger='click' menu={{ items: renderActionMenuItems(template) }}>
            <Button type='text' icon={<FaIcon icon='faEllipsisVertical' />} onClick={(e) => e.stopPropagation()} />
          </Dropdown>
        )
      },
    },
  ]

  return (
    <Table
      dataSource={templates}
      columns={columns}
      loading={isFetching}
      rowKey='id'
      pagination={{
        ...pagination,
        onChange: pagination.setCurrentPage,
        onShowSizeChange: (page, size) => pagination.setPageSize(size),
      }}
      onRow={(template) => ({
        className: 'hover-pointer',
        onClick: () => goToTemplate(template),
      })}
      style={{ maxHeight: '100%', padding: '0' }}
      {...rest}
    />
  )
}

TemplateTableList.defaultProps = {
  templates: [],
  isLoading: false,
  grid: GRID,
}

function getPageSizeOptions(grid, breakpoint) {
  const cardsPerRow = grid[breakpoint]
  return Array.apply(null, Array(5)).map((v, i) => cardsPerRow * 2 * (i + 1))
}
