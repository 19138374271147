import { documintApi } from '.'

export const usersApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    createUserFeedback: build.mutation({
      query: (data) => ({ url: `/users/feedback`, method: 'POST', body: data }),
    }),
  }),
})

export const { useCreateUserFeedbackMutation } = usersApi

export default usersApi
