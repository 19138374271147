import React from 'react'
import { List } from 'antd'

export default function Grid(props) {
  const { items, grid, renderItem, isLoading, onSelect, onDelete, isDeleting, itemProps, pagination, ...rest } = props

  const renderItemWrapper = (...args) => {
    return <List.Item className='grid-item'>{renderItem(...args)}</List.Item>
  }

  return (
    <List
      grid={grid}
      dataSource={items}
      renderItem={renderItemWrapper}
      pagination={pagination}
      loading={isLoading}
      className='grid-list'
      {...rest}
    />
  )
}

Grid.defaultProps = {
  items: [],
  renderItem: () => <></>,
  itemProps: {},
  grid: { gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 6, xxl: 5 },
}
