import React from 'react'

import { useSubscriptionContext } from '@features/subscription/context/SubscriptionContext'
import { formatNumber } from '@utils/data'
import DetailList from '@components/DetailList'

export default function SubscriptionPlanDetails({ ...props }) {
  const { subscription, isLoading } = useSubscriptionContext()
  const plan = subscription.currentPlan || {}
  const { name, price, pricePerAdditional = 0, documentMerges } = plan

  const items = [
    { label: 'Name', value: `${name} Plan` },
    {
      label: 'Price',
      value: (
        <div className='text-right'>
          {formatNumber(price, '$0,0')} / mo.
          <br />
          <span style={{ fontSize: '.9em', color: '#888' }}>
            {pricePerAdditional > 0 && ' + ' + formatNumber(pricePerAdditional, '$0.[000]') + ' / additional merge'}
          </span>
        </div>
      ),
    },
    {
      label: 'Minted Documents',
      value: <>{formatNumber(documentMerges, '0,0')} / month</>,
      tooltip: (
        <>
          This is the number of non-watermarked documents included with your plan. Additional merges will be{' '}
          {pricePerAdditional > 0
            ? `billed at ${formatNumber(pricePerAdditional, '$0.000')} per merge.`
            : 'watermarked.'}
        </>
      ),
    },
  ]

  return <DetailList items={items} isLoading={isLoading} {...props} />
}

SubscriptionPlanDetails.defaultProps = {}
