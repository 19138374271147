import FaIcon from '../../../components/icons/FaIcon'
import { useTemplatesContext } from '../context/TemplateContext'

function useActionMenuItems() {
  const templateContext = useTemplatesContext()
  const { open, rename, duplicate, isDuplicating, toggleTrashed, toggleActive, del } = templateContext

  const renderActionMenuItems = (template) => {
    const menuItems = [
      {
        key: 'edit',
        label: <>Edit</>,
        icon: <FaIcon icon='faPen' />,
        onClick: ({ domEvent: e }) => {
          open(template)
        },
      },
      {
        key: 'rename',
        label: <>Rename</>,
        icon: <FaIcon icon='faICursor' />,
        onClick: ({ domEvent: e }) => {
          e.stopPropagation()
          rename(template)
        },
      },
    ]

    if (template.isTrashed !== true) {
      menuItems.push(
        {
          key: 'active',
          label: <>{template.isActive ? 'Deactivate' : 'Activate'}</>,
          icon: <FaIcon icon={template.isActive ? 'faToggleOff' : 'faToggleOn'} />,
          onClick: ({ domEvent: e }) => {
            e.stopPropagation()
            toggleActive(template)
          },
        },
        {
          key: 'duplicate',
          label: <>Duplicate</>,
          icon: <FaIcon icon='faCopy' />,
          onClick: ({ domEvent: e }) => {
            e.stopPropagation()
            duplicate(template)
          },
          isLoading: isDuplicating,
        },
        {
          key: 'trash',
          label: 'Move to Trash',
          icon: <FaIcon icon='faTrash' />,
          onClick: ({ domEvent: e }) => {
            e.stopPropagation()
            toggleTrashed(template)
          },
        }
      )
    } else {
      menuItems.push(
        {
          key: 'trash',
          label: 'Restore from Trash',
          icon: <FaIcon icon='faUp' />,
          onClick: ({ domEvent: e }) => {
            e.stopPropagation()
            toggleTrashed(template)
          },
        },
        {
          key: 'delete',
          label: 'Delete permanently',
          icon: <FaIcon icon='faTrash' />,
          danger: true,
          onClick: ({ domEvent: e }) => {
            e.stopPropagation()
            del(template)
          },
        }
      )
    }

    return menuItems
  }

  return renderActionMenuItems
}
export default useActionMenuItems
