import { documintApi } from '.'

export const subscriptionApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getSubscription: build.query({
      query: () => '/subscription',
      providesTags: ['Subscription'],
    }),

    changePlan: build.mutation({
      query: (planId) => ({ url: '/subscription/change-plan', method: 'POST', body: { planId } }),
      invalidatesTags: ['Subscription'],
    }),

    cancelScheduledUpdate: build.mutation({
      query: () => ({ url: 'subscription/cancel-schedule', method: 'POST' }),
      invalidateTags: ['Subscription'],
    }),

    // PAYMENT METHODS
    getPaymentMethods: build.query({
      query: () => '/subscription/payment-methods',
      providesTags: ({ data }) =>
        data?.data
          ? [...data?.data?.map?.(({ id }) => ({ type: 'PaymentMethods', id })), { type: 'PaymentMethods', id: 'LIST' }]
          : [{ type: 'PaymentMethods', id: 'LIST' }],
    }),

    attachPaymentMethod: build.mutation({
      query: (paymentMethodId, { isDefault, ...rest } = {}) => ({
        url: '/subscription/payment-methods/attach',
        method: 'POST',
        body: { paymentMethodId, isDefault, ...rest },
      }),
      invalidatesTags: ['PaymentMethods'],
    }),

    deletePaymentMethod: build.mutation({
      query: (paymentMethodId) => ({ url: `/subscription/payment-methods/${paymentMethodId}`, method: 'DELETE' }),
      invalidatesTags: ['PaymentMethods'],
    }),

    setDefaultPaymentMethod: build.mutation({
      query: (paymentMethodId) => ({
        url: 'subscription/payment-methods/default',
        method: 'PUT',
        body: { paymentMethodId },
      }),
      invalidatesTags: ['PaymentMethods', 'Subscription'],
    }),

    // HOSTED PAGES
    createPortalPage: build.mutation({
      query: (returnUrl) => ({ url: '/subscription/portal', method: 'POST', body: { returnUrl } }),
    }),

    createCheckoutPage: build.mutation({
      query: ({ planId, successUrl, cancelUrl }) => ({
        url: '/subscription/hosted-pages/checkout',
        method: 'POST',
        body: { planId, successUrl, cancelUrl },
      }),
    }),

    // INVOICES
    getUpcomingInvoice: build.query({
      query: () => '/subscription/invoices/upcoming',
      providesTags: ['UpcomingInvoice'],
    }),
  }),
})

export const {
  useGetSubscriptionQuery,
  useChangePlanMutation,
  useCreatePortalPageMutation,
  useCreateCheckoutPageMutation,
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useAttachPaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
  useGetUpcomingInvoiceQuery,
  useCancelScheduledUpdateMutation,
} = subscriptionApi

export default subscriptionApi
