export const DEFAULT_ACCOUNT = {
  company: {
    name: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    logo: {},
  },
}

export const CONFIRM_DELETE_TEXT = 'delete account'
