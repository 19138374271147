import React from 'react'
import { Button } from 'antd'
import { useSubscriptionContext } from '@features/subscription/context/SubscriptionContext'

export default function SubscriptionManageBillingButton({ ...props }) {
  const { portalPage } = useSubscriptionContext()

  async function handleManageBilling() {
    const { error, data: url } = await portalPage.create(window.location.href)
    console.log({ error, url })
    if (url && !error) window.location.href = url
  }
  return (
    <Button size='small' key='manage-billing' onClick={handleManageBilling} loading={portalPage.isLoading} {...props}>
      Manage billing
    </Button>
  )
}
