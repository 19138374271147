import { documintApi } from '.'
import _ from 'lodash'

export const assetsApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getAssets: build.query({
      query: ({ page, limit, select, sort = '-createdAt' } = {}) => {
        const queryString = _.chain({ page, limit, select, sort })
          .pickBy()
          .toPairs()
          .value()
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
        return `/assets?${queryString}`
      },
      providesTags: ({ data }) =>
        data?.data
          ? [...data?.data?.map?.(({ id }) => ({ type: 'Assets', id })), { type: 'Assets', id: 'LIST' }]
          : [{ type: 'Assets', id: 'LIST' }],
    }),
    addAsset: build.mutation({
      query: () => {},
    }),
    updateAsset: build.mutation({
      query: (id, patch) => ({ url: `/assets/${id}`, body: patch }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Assets', id },
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    deleteAsset: build.mutation({
      query: (id) => ({ url: `/assets/${id}`, method: 'DELETE' }),
      invalidatesTags: (asset) => {
        return [
          { type: 'Assets', id: asset?.id },
          { type: 'Assets', id: 'LIST' },
        ]
      },
    }),
  }),
})

export const { useGetAssetsQuery, useUpdateAssetMutation, useDeleteAssetMutation } = assetsApi

export default assetsApi
