import React from 'react'

import { Layout } from 'antd'
import TopBar from '../components/layout/TopBar'
import SideBar from '../components/layout/SideBar'
import { NewTemplateProvider } from '../contexts/NewTemplateContext'
import { SubscriptionProvider } from '@features/subscription'
import { useBreakpoint } from '../hooks/helperHooks'

const { Content } = Layout

export default function DashboardLayout({ children, className, ...rest }) {
  const breakpoint = useBreakpoint()

  const contentStyle = {
    xs: { padding: '0px' },
    sm: { padding: '0px' },
    md: { padding: '20px' },
    lg: { padding: '20px' },
    xl: { padding: '20px' },
    xxl: { padding: '20px' },
  }[breakpoint]

  return (
    <Layout className={`layout-dashboard ${className}`} {...rest} theme='light'>
      <SubscriptionProvider>
        <NewTemplateProvider>
          <TopBar />
          <Layout>
            <SideBar props={children.props} />
            <Layout>
              <Content className='dm-content' style={contentStyle}>
                {children}
              </Content>
            </Layout>
          </Layout>
        </NewTemplateProvider>
      </SubscriptionProvider>
    </Layout>
  )
}
