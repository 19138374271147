import React from 'react'
import { Form, Input, Button, Space, Checkbox } from 'antd'

import isFunction from 'lodash/isFunction'
import { DEFAULT_PROFILE } from './../consts'

export default function ProfileForm(props) {
  const { profile, onSubmit, isUpdating, onCancel } = props
  const { name = {}, email, preferences = {} } = profile

  const fields = [
    { name: ['name', 'givenName'], value: name.givenName },
    { name: ['name', 'familyName'], value: name.familyName },
    { name: ['email'], value: email },
    { name: ['preferences', 'emailOptIn'], value: preferences.emailOptIn },
  ]

  const handleSubmit = async (values) => {
    isFunction(onSubmit) && onSubmit(values)
  }
  const handleCancel = () => {
    isFunction(onCancel) && onCancel()
  }

  return (
    <Form name='user-profile' layout='vertical' fields={fields} onFinish={handleSubmit}>
      <Form.Item label='First Name' name={['name', 'givenName']}>
        <Input placeholder='First Name' />
      </Form.Item>

      <Form.Item label='Last Name' name={['name', 'familyName']}>
        <Input placeholder='Last Name' />
      </Form.Item>

      <Form.Item label='Email Address' name='email'>
        <Input placeholder='Email Address' type='email' />
      </Form.Item>

      <Form.Item label='' name={['preferences', 'emailOptIn']} valuePropName='checked'>
        <Checkbox>Notify me about updates and promotions.</Checkbox>
      </Form.Item>

      <Form.Item className='text-center'>
        <Space>
          <Button onClick={handleCancel} type='text'>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isUpdating}>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

ProfileForm.defaultProps = {
  profile: DEFAULT_PROFILE,
  onSubmit: (values) => values,
  isUpdating: false,
  onCancel: () => {},
}
