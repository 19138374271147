import React from 'react'
import { Modal } from 'antd'
import UserFeedbackForm from './UserFeedbackForm'
import { useDispatch, useSelector } from 'react-redux'
import { selectModalOpen, closeUserFeedbackModal } from '../slice'

const UserFeedbackModal = () => {
  const modalOpen = useSelector(selectModalOpen)
  const dispatch = useDispatch()

  const handleFinish = () => {
    dispatch(closeUserFeedbackModal())
  }

  return (
    <Modal
      open={modalOpen}
      onCancel={() => dispatch(closeUserFeedbackModal())}
      title='Share your feedback with our team'
      footer={false}
    >
      <UserFeedbackForm onFinish={handleFinish} />
    </Modal>
  )
}

export default UserFeedbackModal
