import React from 'react'
import { notification, PageHeader } from 'antd'
import { useGetGalleryTemplatesQuery } from '../../../services/documintApi/templates'
import { useCreateTemplateMutation } from '../../../services/documintApi/templates'
import TemplateGridListItem from './TemplateGridListItem'
import { useHistory } from 'react-router-dom'
import Grid from '../../../components/Grid'

const GRID = { gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4 }

export default function TemplateGalleryList({ queryParams, showTitle, ...rest }) {
  const history = useHistory()
  const { data = {}, isLoading } = useGetGalleryTemplatesQuery({ ...queryParams })
  const { data: templates = [] } = data

  const [createTemplate] = useCreateTemplateMutation()

  const handleClick = async (template) => {
    const { data, error } = await createTemplate(template)
    if (error) return notification.error({ message: 'Something went wrong', description: error.data.message })
    history.push(`/templates/${data.id}`)
  }

  function renderItem(template) {
    return <TemplateGridListItem template={template} showStatus={false} onClick={handleClick} />
  }

  const blankTemplate = { name: 'Blank', thumbnail: { url: '/images/icons/new-blank-template.png' } }

  return (
    <>
      {showTitle && <PageHeader title='Template Gallery' />}
      <Grid items={[blankTemplate, ...templates]} renderItem={renderItem} isLoading={isLoading} grid={GRID} {...rest} />
    </>
  )
}
TemplateGalleryList.defaultProps = {
  queryParams: {},
  showTitle: false,
}
