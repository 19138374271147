import React from 'react'
import { Col, Row, Card, Button, Tooltip, notification, message, PageHeader } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import FaIcon from '@components/icons/FaIcon'
import { useModal } from '@ebay/nice-modal-react'

import { useToggle } from '@hooks/helperHooks'
import integrationsApi from '@documintApi/integrations'
import { ApiAppList, NewApiAppModal } from '@features/apiApps'

import { IntegrationCard, IntegrationModal } from '@features/integrations'

export default function Integrations() {
  const modal = useModal(IntegrationModal)
  const { data = {}, isLoading } = integrationsApi.useGetIntegrationsQuery()
  const { airtable = {} } = data
  const [add, { isLoading: isSaving }] = integrationsApi.useAddIntegrationMutation()
  const [del, { isLoading: isDeleting }] = integrationsApi.useDeleteIntegrationMutation()

  const handleSave = async (data) => {
    const { error } = await add(data)
    if (error) return notification.error({ message: 'Something went wrong', description: error?.data?.message })
    message.success('Success')
  }

  const handleDelete = async (name) => {
    const { error } = await del(name)
    if (error) notification.error({ message: 'Something went wrong', description: error?.data?.message })
  }

  const integrations = [
    {
      key: 'airtable',
      name: 'Airtable',
      image: '/images/delivery-methods/airtable-logo.png',
      type: 'api-key',
      field: {
        label: 'Personal Access Token',
        name: 'apiKey',
        rules: [{ required: true }, { pattern: /^pat/, message: 'Invalid Airtable Personal Access Token.' }],
        tooltip: (
          <>
            How to
            <a href='https://docs.documint.me' target='_blank' rel='noreferrer'>
              {' '}
              find Personal Access Token
            </a>
            ?
          </>
        ),
      },
      hasValue: airtable?.hasApiKey || false,
      onSave: async (values) => await handleSave({ airtable: values }),
      onEdit: () => {},
      onDelete: async () => await handleDelete('airtable'),
    },
  ]

  const fetchState = { isLoading, isSaving, isDeleting }
  const [newModalIsOpen, toggleNewApiAppModal] = useToggle(false)

  const actions = [
    <Button key='add' onClick={() => toggleNewApiAppModal(true)} icon={<FaIcon icon='faKey' />}>
      Create API Key
    </Button>,
  ]
  return (
    <PageHeader title='Integrations' style={{ maxWidth: '1100px' }}>
      <Card
        title={
          <>
            Api Keys{' '}
            <Tooltip title='Grant other applications access to your Documint account with Api Keys.'>
              <InfoCircleOutlined />
            </Tooltip>
          </>
        }
        extra={actions}
        style={{ marginBottom: '1.5em' }}
      >
        <ApiAppList />

        <NewApiAppModal
          open={newModalIsOpen}
          onFinish={() => toggleNewApiAppModal(false)}
          onCancel={() => toggleNewApiAppModal(false)}
        />
      </Card>
      <Card
        title={
          <>
            Third-Party Integrations{' '}
            <Tooltip title='Grant Documint access to your other applications'>
              <InfoCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Row gutter={[16, 16]} style={{ marginBottom: '1em' }}>
          <Col xl={6}>
            {integrations.map(({ onSave, onEdit, onDelete, ...rest }) => (
              <IntegrationCard
                key={rest.key}
                data={rest}
                onDelete={onDelete}
                {...fetchState}
                onAdd={() => modal.show({ onSave, ...rest, ...fetchState })}
                onEdit={() => modal.show({ onSave, ...rest, ...fetchState })}
              />
            ))}
          </Col>
        </Row>
      </Card>
    </PageHeader>
  )
}
