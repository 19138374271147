import { documintApi } from '.'

export const plansApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getPlans: build.query({
      query: () => '/plans',
      providesTags: ({ data }) =>
        data?.data
          ? [...data?.data?.map?.(({ id }) => ({ type: 'Plans', id })), { type: 'Plans', id: 'LIST' }]
          : [{ type: 'Plans', id: 'LIST' }],
    }),
    getPlan: build.query({
      query: ({ id }) => `/plans/${id}`,
      providesTags: (plan, error, id) => [{ type: 'Posts', id }],
    }),
  }),
})

export const { useGetPlansQuery, useGetPlanQuery } = plansApi

export default plansApi
