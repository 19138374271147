import React from 'react'
import { Modal, Layout } from 'antd'
import { TemplateGalleryList } from '..'

export default function CreateTemplateModal({ isVisible, onClose }) {
  return (
    <Modal
      title='Create a Template'
      open={isVisible}
      footer={null}
      onCancel={onClose}
      width={900}
      className='new-template-modal'
      bodyStyle={{ padding: 0, borderRadius: '0 0 10px 10px', overflow: 'hidden' }}
    >
      <Layout>
        <Layout.Content className='p-3'>
          <TemplateGalleryList layout='grid' />
        </Layout.Content>
      </Layout>
    </Modal>
  )
}

CreateTemplateModal.defaultProps = {
  onCancel: () => {},
}
