import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import Loading from './Loading'
import { isUnchanged } from './../../utils/index'

import { useGetProfile, useValidateSession } from '../../features/auth/authHooks'

function PrivateRoute({ component, layout: Layout, children, path, location, ...rest }) {
  const profile = useGetProfile()
  const [validate, { isValidating }] = useValidateSession()
  const history = useHistory()

  useEffect(() => {
    if (profile || isValidating) return
    validate().then((isValid) => {
      if (isValid === false) history.push({ pathname: '/login', state: { from: location } })
    })
  }, [history, isValidating, validate, profile, location])

  if (!profile) return <Loading />

  if (children) return <Route children={children} />

  return <Route {...rest} component={component} />
}
export default React.memo(PrivateRoute, (prev, curr) => isUnchanged(prev, curr, ['path']))
