import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Typography } from 'antd'

import { useCheckoutContext } from '@features/subscription'
import FaIcon from '@components/icons/FaIcon'

const { Title } = Typography

export default function CheckoutHeader({ title, showBack, showClose, style, ...rest }) {
  const { prevStep, stepIndex } = useCheckoutContext()
  const history = useHistory()
  const goBack = () => history.goBack()

  return (
    <div style={{ display: 'flex', flex: '1 1 100%', flexDirection: 'row', ...style }} {...rest}>
      <div style={{ flex: '0 0 60px' }}>
        {showBack && (
          <Button
            icon={<FaIcon icon='faArrowLeft' style={{ fontSize: '1.5em', color: '#AAA' }} />}
            type='text'
            onClick={prevStep}
            hidden={stepIndex <= 0}
          />
        )}
      </div>

      <Title level={4} style={{ fontSize: '1.8em', fontWeight: '500', textAlign: 'center', flex: '1 1 auto' }}>
        {title}
      </Title>

      <div style={{ flex: '0 0 60px', display: 'flex', justifyContent: 'flex-end' }}>
        {showClose && (
          <Button
            icon={<FaIcon icon='faClose' style={{ fontSize: '1.5em', color: '#AAA' }} />}
            type='text'
            onClick={goBack}
          />
        )}
      </div>
    </div>
  )
}

CheckoutHeader.defaultProps = {
  title: '',
  showBack: true,
  showClose: true,
  style: {},
}
