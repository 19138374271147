import React, { createContext, useContext, useCallback, useRef } from 'react'
import usePreview from '../hooks/previewHooks'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'

const PreviewerContext = createContext()

export function usePreviewerContext() {
  return useContext(PreviewerContext)
}

export function PreviewerProvider({ children }) {
  const [create, { data, isLoading, error }] = usePreview()
  const cache = useRef({ key: {}, value: null })

  const value = {
    /**
     * Create preview
     * @param {Object} testData
     */
    create: useCallback(
      async (template, mergeData) => {
        const TEMPLATE_PROPS = ['html', 'css', 'headerTemplate', 'footerTemplate', 'options', 'renderEngine']
        template = pick(template, TEMPLATE_PROPS)

        // Cache
        const cachedItem = isEqual(cache.current.key, { ...template, mergeData }) && cache.current.value

        if (cachedItem) return [null, cachedItem]

        const [error, pdf] = await create(template, mergeData)

        if (!error) cache.current = { key: { ...template, mergeData }, value: pdf }

        return [error, pdf]
      },
      [create]
    ),
    data,
    isLoading,
    error,
  }

  return <PreviewerContext.Provider value={value}>{children}</PreviewerContext.Provider>
}

const previewerContext = { PreviewerContext, usePreviewerContext }

export default previewerContext
