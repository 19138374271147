import React, { useState } from 'react'
import { Alert, ConfigProvider, Space, PageHeader, Segmented } from 'antd'
import {
  TemplatesProvider,
  TemplatesSearch,
  TemplatesFilters,
  TemplatesSort,
  TemplateGridList,
  TemplateTableList,
} from '../../features/templates'
import Loading from '@components/common/Loading'
import FaIcon from '../../components/icons/FaIcon'

const VIEW_MODE_KEY = 'templateListViewMode'

export default function Templates() {
  const initialViewMode = sessionStorage.getItem(VIEW_MODE_KEY) || 'grid'
  const [viewMode, setViewMode] = useState(initialViewMode)

  const handleViewModeChange = (mode) => {
    setViewMode(mode)
    sessionStorage.setItem(VIEW_MODE_KEY, mode)
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <Alert.ErrorBoundary>
        <TemplatesProvider query={{ isTrashed: false }}>
          <div style={{ maxWidth: '1700px' }}>
            <ConfigProvider>
              <PageHeader
                title='My Templates'
                extra={
                  <Space size='large'>
                    <TemplatesSearch />
                    <TemplatesSort />
                    <TemplatesFilters />
                    <>|</>
                    <Segmented
                      defaultValue={viewMode}
                      options={[
                        { icon: <FaIcon icon='faGrid2' />, value: 'grid' },
                        { icon: <FaIcon icon='faTableList' />, value: 'table' },
                      ]}
                      onChange={handleViewModeChange}
                    />
                  </Space>
                }
              >
                {viewMode === 'grid' ? <TemplateGridList /> : <TemplateTableList />}
              </PageHeader>
            </ConfigProvider>
          </div>
        </TemplatesProvider>
      </Alert.ErrorBoundary>
    </React.Suspense>
  )
}
