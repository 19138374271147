import { documintApi } from '.'

export const apiAppsApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getApiApps: build.query({
      query: ({ page = 1, limit = 20, sort = '-updatedAt' } = {}) =>
        `api-apps?page=${page}&limit=${limit}&sort=${sort}`,
      providesTags: ({ data }) =>
        data?.data
          ? [...data?.data?.map?.(({ id }) => ({ type: 'ApiApps', id })), { type: 'ApiApps', id: 'LIST' }]
          : [{ type: 'ApiApps', id: 'LIST' }],
    }),

    addApiApp: build.mutation({
      query: (body) => ({ url: `api-apps`, method: 'POST', body }),
      invalidatesTags: ['ApiApps'],
    }),

    updateApiApp: build.mutation({
      query: ({ id, ...patch }) => ({ url: `/api-apps/${id}`, method: 'PUT', body: patch }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ApiApps', id },
        { type: 'ApiApps', id: 'LIST' },
      ],
    }),

    deleteApiApp: build.mutation({
      query: (id) => ({ url: `/api-apps/${id}`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'ApiApps', id: 'LIST' }],
    }),
  }),
})

export const { useGetApiAppsQuery, useAddApiAppMutation, useUpdateApiAppMutation, useDeleteApiAppMutation } = apiAppsApi

export default apiAppsApi
