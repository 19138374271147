import React from 'react'
import { Modal } from 'antd'

import PaymentMethods from './PaymentMethods'

export default function PaymentMethodModal({ isOpen, onClose, onOk }) {
  return (
    <Modal open={isOpen} closable={true} onCancel={onClose} onOk={onOk} footer={false}>
      <PaymentMethods />
    </Modal>
  )
}

PaymentMethodModal.defaultProps = { isOpen: false }
