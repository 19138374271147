import React from 'react'
import { Form, Input, Button, Typography, Tooltip } from 'antd'
import isFunction from 'lodash/isFunction'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Title } = Typography

export default function ApiKeyForm({ rules, name, label, tooltip, onSave, onDelete, ...rest }) {
  const [formRef] = Form.useForm()

  const handleFinish = (values) => {
    isFunction(onSave) && onSave(values)
  }

  return (
    <Form
      layout='vertical'
      onFinish={handleFinish}
      form={formRef}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      size='large'
      {...rest}
    >
      <Form.Item
        name={[name]}
        rules={rules}
        label={
          <Title level={5}>
            {label}{' '}
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Title>
        }
        style={{ marginBottom: '1em' }}
      >
        <Input placeholder={`Paste your ${label} here...`} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right' }}>
        <Button htmlType='submit' className='mr-2' type='primary'>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

ApiKeyForm.defaultProps = {
  fieldName: 'apiKey',
  fieldLabel: 'Api Key',
}