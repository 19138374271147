import React, { useState, useRef } from 'react'
import 'react-pdf/dist/umd/Page/AnnotationLayer.css'

import { Space } from 'antd'
import { Document, Page } from 'react-pdf'
import { CloudDownloadOutlined } from '@ant-design/icons'
import LoadingDocument from '../LoadingDocument'
import Button from '../Button'
import ScaleControls from '../ScaleControls'
import Display from '../Display'
import { useDownload } from '../../../hooks/helperHooks'
import { useResizeDetector } from 'react-resize-detector'
import BrandBadge from '../brand/BrandBadge'
import useScale from '../../../hooks/helperHooks/useScale'

export default function DefaultViewer({
  data,
  controls,
  loaderProps,
  onDownloadSuccess,
  branding,
  isLoading,
  error,
  padding,
}) {
  const [loadError, setLoadError] = useState(null)
  const [page] = useState(null)
  const download = useDownload()
  const { ref: frameRef } = useResizeDetector()
  const pdfObj = useRef(null)
  const scale = useScale({ min: 0.2, max: 6, incrementor: 0.4, containerRef: frameRef, padding })

  const getDimensionsFromPdfObject = (pdfObject) => {
    return new Promise((resolve, reject) => {
      pdfObject
        .getPage(1)
        .then(({ view }) => {
          const width = view[2]
          const height = view[3]
          resolve({ width, height })
        })
        .catch((error) => reject(error))
    })
  }

  const handleLoadError = (err) => {
    const message = err.message || 'Ooops, something went wrong.'
    setLoadError({ message })
  }

  function handleDownload() {
    download(data, { onSuccess: onDownloadSuccess })
  }

  const handleLoadSuccess = async (pdfObject) => {
    try {
      pdfObj.current = pdfObject
      const initialDims = await getDimensionsFromPdfObject(pdfObject)
      scale.setInitialDims(initialDims)
      scale.setMode('fit_page')
    } catch (error) {
      setLoadError(error)
    }
  }

  const handleScaleUp = () => scale.increment()
  const handleScaleDown = () => scale.decrement()
  const handleResetScale = () => scale.setValue(1)
  const handleModeChange = (mode) => scale.toggleMode(mode)

  const CONTROLS = {
    download: <Button onClick={handleDownload} icon={<CloudDownloadOutlined />} size='large' key='download' />,
    scale: (
      <ScaleControls
        key='scale-controls'
        scale={scale.value}
        mode={scale.mode}
        onIncrement={handleScaleUp}
        onDecrement={handleScaleDown}
        onModeChange={handleModeChange}
        onReset={handleResetScale}
        orientation={'vertical'}
        controls={['fit', ' ', 'scaleUp', 'scaleDown']}
      />
    ),
    ' ': <span key='space'></span>,
  }

  const loader = (
    <div style={{ width: '100%', height: '100%', padding: '20px' }}>
      <LoadingDocument {...loaderProps} height={page?.height} width={page?.width} />
    </div>
  )

  error = error || loadError

  return (
    <div className='pdf-viewer__inner-frame' ref={frameRef}>
      <Display isLoading={isLoading} loader={loader} error={error} showErrorDetails={true}>
        <Space direction='vertical' align='center' className='pdf-viewer__actions' style={{ marginBottom: '4em' }}>
          {controls.map((c) => CONTROLS[c])}
        </Space>

        <div className='pdf-viewer__document-wrapper' style={{ paddingTop: '1em' }}>
          <Document
            file={data}
            onLoadSuccess={handleLoadSuccess}
            onLoadError={handleLoadError}
            loading={loader}
            noData={loader}
          >
            {pdfObj.current &&
              Array.from(new Array(pdfObj.current?.numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={scale?.width} height={scale?.height} />
              ))}
          </Document>
        </div>
      </Display>

      {branding && <BrandBadge />}
    </div>
  )
}

DefaultViewer.defaultProps = {
  onDownloadSuccess() {},
  controls: ['scale'],
}
