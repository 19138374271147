import React from 'react'

export default function BrandBadge() {
  return (
    <a
      href='https://www.documint.me?utm_source=document_viewer'
      target='_blank'
      rel='noopener noreferrer'
      style={{ padding: '30px 0' }}
    >
      <img
        src='https://documint.s3.amazonaws.com/assets/brand/documint-logo--powered-by.svg'
        alt='Powered by Documint'
        style={{ maxWidth: '200px' }}
      />
    </a>
  )
}
