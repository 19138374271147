import React from 'react'
import { Card, Popconfirm, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'

export default function IntegrationCard({ data, onAdd, onEdit, onDelete, isLoading, isDeleting }) {
  const { name, description, image, hasValue } = data

  let actions

  if (hasValue) {
    actions = [
      <EditOutlined onClick={onEdit} key='edit' />,
      <Popconfirm
        title='Are you sure to delete this integration?'
        okText='Yes, delete'
        cancelText='No'
        onConfirm={onDelete}
        okButtonProps={{ loading: isDeleting }}
        key='delete'
      >
        <DeleteOutlined />
      </Popconfirm>,
    ]
  } else {
    actions = [<PlusOutlined onClick={onAdd} key='add' />]
  }

  return (
    <Card
      cover={
        <div style={{ padding: '30px 30px 0' }}>
          <img alt={name} src={image} width='100%' />
        </div>
      }
      bodyStyle={{ justifyContent: 'center' }}
      actions={actions}
      loading={isLoading}
    >
      <Skeleton loading={isLoading}>
        <Card.Meta title={name} description={description} style={{ justifyContent: 'center' }} />
      </Skeleton>
    </Card>
  )
}

IntegrationCard.defaultProps = {
  data: {
    name: '',
    description: '',
    image:
      'https://banner2.cleanpng.com/20180811/tkc/kisspng-cloud-computing-computer-icons-cloud-storage-icon-push-5b6e852294bf48.8314517315339696986093.jpg',
    buttonText: 'Connect',
  },
  onAdd: () => {},
  onEdit: () => {},
  onDelete: () => {},
}
