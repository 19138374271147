import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Layout, Menu, Card } from 'antd'
import { useToggle } from '../../hooks/helperHooks'
import FaIcon from '../icons/FaIcon'

import { SubscriptionUsageChart, SubscriptionProvider, ChangePlanButton } from '@features/subscription'

const { Sider } = Layout

export default function SideBar() {
  const history = useHistory()
  const [collapsed, toggleCollapse] = useToggle(false)
  const location = useLocation()

  const handleClick = (item) => {
    history.push(item.item.props.path)
  }

  const getKeyFromLocation = (location) => {
    const { pathname, hash } = location
    let result = pathname.replace(/^\//, '').replace(/\/$/, '').replace('/', '-')
    if (hash.length > 0) result += '-' + hash.replace(/^#/, '')
    return result
  }

  const defaultSelected = getKeyFromLocation(location)
  const defaultOpenKeys = () => {
    const keys = ['templates-group']
    getKeyFromLocation(location).match(/^settings/) && keys.push('settings')
    return keys
  }
  const selectedKeys = getKeyFromLocation(location)

  const menuItems = [
    {
      key: 'templates-group',
      label: 'Templates',
      icon: <FaIcon icon='faFileInvoice' />,
      children: [
        { label: 'My Templates', key: 'templates', path: '/templates' },
        { label: 'Trash', key: 'templates-trash', path: '/templates/trash' },
      ],
    },
    { key: 'assets', label: 'Assets', icon: <FaIcon icon='faImage' />, path: '/assets' },
    {
      key: 'settings',
      label: 'Settings',
      icon: <FaIcon icon='faSliders' />,
      path: '/settings',
      children: [
        { label: 'My Profile', key: 'settings-profile', path: '/settings/profile' },
        { label: 'Account', key: 'settings-account', path: '/settings/account' },
        { label: 'Subscription', key: 'settings-subscription', path: '/settings/subscription' },
        { label: 'Integrations', key: 'settings-integrations', path: '/settings/integrations' },
      ],
    },
  ]

  return (
    <SubscriptionProvider>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapse}
        id='dm-sidebar'
        className='dm-sidebar'
        breakpoint='lg'
        theme='light'
        width='250px'
      >
        <div style={{ display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column' }}>
          {/* TOP ITEMS */}
          <Menu
            defaultOpenKeys={defaultOpenKeys()}
            defaultSelectedKeys={[defaultSelected]}
            selectedKeys={[selectedKeys]}
            theme='light'
            mode='inline'
            className='dm-sidebar__menu mb-auto'
            style={{ width: '100%' }}
            items={menuItems}
            onClick={handleClick}
          />
          {!collapsed && (
            <div className='px-3'>
              <Card style={{ backgroundColor: '#F9F9F9' }}>
                <SubscriptionUsageChart strokeWidth={8} showValue={false} className='mb-2' trailColor='#FFF' />
                <ChangePlanButton block />
              </Card>
            </div>
          )}
        </div>
      </Sider>
    </SubscriptionProvider>
  )
}
