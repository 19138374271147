import React from 'react'
import { List, Typography } from 'antd'
import { formatNumber } from '@utils'
const { Title, Text } = Typography

export default function CheckoutLineItem({ items }) {
  function renderItem({ name, description, price }) {
    return (
      <List.Item>
        <div
          style={{
            backgroundColor: '#009BFF',
            width: '70px',
            height: '70px',
            borderRadius: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '40px',
            color: '#fff',
            marginRight: '15px',
          }}
        >
          {name.slice(0, 1)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flex: '1 1 auto' }}>
          <div style={{ flex: '1 1 auto', textAlign: 'left' }}>
            <Title level={5}>{name}</Title>
            <Text disabled>{description}</Text>
          </div>
          <div style={{ flex: '0 0 auto' }}>
            <Text>{formatNumber(price, '$0,0.00')} USD</Text>
          </div>
        </div>
      </List.Item>
    )
  }

  return <List dataSource={items} renderItem={renderItem} />
}

CheckoutLineItem.defaultProps = {
  items: [],
}
