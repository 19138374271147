import _, { isNil } from 'lodash'
import barhandles from 'barhandles'

/**
 * Returns a Collection of path
 * objects from a JSON schema
 * @param {Object} schemaObject
 * @param {Object} [parentPath]
 */
export function getPathsFromJSONSchema(schemaObject, parent = {}) {
  if (!_.isObject(schemaObject)) return

  const items = []

  _.forOwn(schemaObject, (value, key) => {
    if (!_.isObject(value)) return

    const path = parent.type && parent.type === 'array' ? parent.path : parent.path ? `${parent.path}.${key}` : key
    const { type, title } = value
    const thisItem = { path, type, title }
    const isPropertiesObject = key === 'properties' && !type
    const isArrayObject = type === 'array'

    // Check if this is the properties object of an array
    if (parent.type && parent.type === 'array' && isPropertiesObject) {
      const childItems = getPathsFromJSONSchema(value, thisItem)
      items.push(...childItems)
      // check if this is a properties object
    } else if (isPropertiesObject) {
      const childItems = getPathsFromJSONSchema(value)
      items.push(...childItems)
      // Check if this is an array object
    } else if (isArrayObject) {
      items.push(thisItem)
      const childItems = getPathsFromJSONSchema(value.items, thisItem)
      items.push(...childItems)
      // check if this is a regular object
    } else if (type === 'object') {
      items.push(thisItem)
      const object = value.properties
      const childItems = getPathsFromJSONSchema(object, thisItem)
      items.push(...childItems)
      // Catchall for text, numbers & boolean
    } else {
      items.push(thisItem)
    }
  })
  return items
}

export function parseTreeSchema(schema, parent = {}) {
  // console.log(schema);
  if (!_.isObject(schema) || _.isEmpty(schema) || !schema.type) return []

  const { type, properties, items } = schema

  switch (type) {
    case 'object': {
      if (isNil(properties)) return []
      return Object.keys(properties).map((key) => {
        const property = properties[key]
        const thisKey = parent.key ? `${parent.key}.${key}` : key
        const thisPath = parent.type === 'array' ? key : thisKey

        const obj = {
          key: thisKey,
          title: `${key}`,
          type: property.type,
          path: thisPath,
          icon: () => {},
        }

        const children = parseTreeSchema(property, { key: thisKey, type })

        if (children) obj.children = children
        return obj
      })
    }
    case 'array': {
      return parseTreeSchema(items, { key: parent.key })
    }
    default:
      return
  }
}

export function getJsonSchemaFromTemplate(template) {
  try {
    if (!template || template === '') return {}
    const barhandlesSchema = barhandles.extractSchema(template)
    return toJsonSchema(barhandlesSchema)
  } catch (error) {
    return error
  }
}

/**
 * Extracts JSON schema from
 * @param {*} schema
 */
export function toJsonSchema(schema) {
  const omitKeys = ['_type', '_optional']
  if (!_.isObject(schema) || _.isEmpty(schema) || !schema._type) return {}

  switch (schema._type) {
    case 'object': {
      let newSchema = {}
      const keys = _.keys(schema).filter((key) => !omitKeys.includes(key))

      // Check if barhandles marked an array as an object and fix it
      const isArray = keys.every((key) => !Number.isNaN(parseInt(key)))
      if (isArray) {
        newSchema = {
          type: 'array',
          items: toJsonSchema(schema[keys[0]]),
        }
        return newSchema
      }
      newSchema = { type: 'object', properties: {} }
      _.each(keys, (key) => {
        newSchema.properties[key] = toJsonSchema(schema[key])
      })
      return newSchema
    }
    case 'array': {
      const newSchema = { type: 'array' }
      newSchema.items = toJsonSchema(schema['#'])
      return newSchema
    }
    case 'any': {
      const newSchema = { type: 'string' }
      return newSchema
    }
    default:
      return
  }
}

function isValidType(value, type) {
  switch (type) {
    case 'array':
      return _.isArray(value)
    case 'object':
      return _.isObject(value)
    default:
      return typeof value === type
  }
}

export const pickWithJsonSchema = (schema = {}, data) => {
  const { type, items, properties } = schema

  if (_.isEmpty(schema)) return {}
  if (_.isNil(data)) return

  switch (type) {
    case 'object': {
      const result = {}
      _.forIn(properties, (value, key) => {
        const childData = data[key]
        if (_.isNil(childData)) return
        const validDataType = isValidType(childData, value.type)
        if (!validDataType) return
        result[key] = pickWithJsonSchema(value, data[key])
      })
      return result
    }
    case 'array': {
      return _.isArray(data) ? data.map((object) => pickWithJsonSchema(items, object)) : undefined
    }
    default:
      return data
  }
}

export const pickFromJsonSchema = (schema, keys) => ({
  ...schema,
  properties: keys.reduce((accumulator, key) => {
    if (typeof schema.properties[key] === 'undefined') return accumulator
    return {
      ...accumulator,
      [key]: schema.properties[key],
    }
  }, {}),
  required: schema.required.filter((req) => keys.includes(req)),
})

const jsonUtils = {
  getPathsFromJSONSchema,
  parseTreeSchema,
  getJsonSchemaFromTemplate,
  toJsonSchema,
  pickWithJsonSchema,
  pickFromJsonSchema,
}

export default jsonUtils
