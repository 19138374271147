import React, { useState, createContext, useContext } from 'react'
import CreateTemplateModal from '../features/templates/components/CreateTemplateModal'

const NewTemplateContext = createContext()

export function useNewTemplate() {
  return useContext(NewTemplateContext)
}

export function NewTemplateProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <NewTemplateContext.Provider value={[isVisible, setIsVisible]}>
      {children}
      <CreateTemplateModal isVisible={isVisible} onClose={() => setIsVisible(false)} />
    </NewTemplateContext.Provider>
  )
}
