import React from 'react'
import { Drawer, Card, Button, Divider, notification, PageHeader } from 'antd'

import { useToggle } from '@hooks/helperHooks'
import { AccountDetails, AccountForm, ConfirmDeleteAccountModal, DeleteAccountAlert } from '@features/account'
import { useGetAccountQuery, useUpdateAccountMutation } from '@documintApi/account'
import { useLogout } from '../../features/auth/authHooks'

export default function Account() {
  const { data, isLoading } = useGetAccountQuery()
  const [update, { isLoading: isUpdating }] = useUpdateAccountMutation()
  const [logout] = useLogout()
  const [isDrawerOpen, toggleDrawer] = useToggle(false)
  const [isDeleteConfirmOpen, toggleDeleteConfirm] = useToggle(false)

  const doUpdate = async (body) => {
    try {
      const { error } = await update(body)
      if (error) throw new Error(error?.data?.message)
    } catch (error) {
      handleError({ description: error.message })
    }
  }

  const handleUpdate = async (body) => {
    await doUpdate(body)
    toggleDrawer(false)
  }

  async function handleDeleteAccount() {
    try {
      await doUpdate({ isActive: false })
      await logout()
    } catch (error) {
      handleError({ description: error?.message || 'An unknown error was encountered.' })
    }
  }

  const actions = [
    <Button onClick={toggleDrawer} key='1'>
      Edit
    </Button>,
  ]

  return (
    <PageHeader title='Account' extra={actions} style={{ maxWidth: '1100px' }}>
      <Card>
        <AccountDetails account={data} isLoading={isLoading} onUpdate={handleUpdate} isUpdating={isUpdating} />

        <Divider />

        <DeleteAccountAlert onClick={() => toggleDeleteConfirm(true)} />

        <Drawer title='Edit Account' open={isDrawerOpen} maskClosable={true} onClose={() => toggleDrawer(false)}>
          <AccountForm account={data} onSubmit={handleUpdate} onCancel={() => toggleDrawer()} />
        </Drawer>

        <ConfirmDeleteAccountModal
          open={isDeleteConfirmOpen}
          onConfirm={handleDeleteAccount}
          onCancel={() => toggleDeleteConfirm(false)}
        />
      </Card>
    </PageHeader>
  )
}

function handleError({ message, description } = {}) {
  if (!message) message = 'Error'
  if (!description) description = 'An unknown error was encountered. Please contact support if the problem persists.'
  notification.error({ message, description })
}
