import { configureStore } from '@reduxjs/toolkit'
import { documintApi } from '../services/documintApi'
import authReducer from '@features/auth/authSlice'
import userFeedbackReducer from '@features/userFeedback/slice'
import { identifyUser, rtkQueryErrorLogger } from './middleware'

export default configureStore({
  reducer: {
    [documintApi.reducerPath]: documintApi.reducer,
    auth: authReducer,
    userFeedback: userFeedbackReducer,
  },
  devTools: true,
  middleware: (getDefaults) => {
    const allMiddleware = getDefaults().concat(documintApi.middleware)
    allMiddleware.push(identifyUser)
    allMiddleware.push(rtkQueryErrorLogger)
    return allMiddleware
  },
})
