import React from 'react'
import { useSubscriptionContext } from '../'
import { Typography, Progress, Badge } from 'antd'
import Loading from '@components/common/Loading'
const { Text } = Typography

export default function SubscriptionUsageChart({
  trailColor,
  type,
  showValue,
  strokeWidth,
  style,
  fontSize,
  progressProps,
  ...rest
}) {
  const { subscription, isLoading } = useSubscriptionContext()

  const usage = subscription?.usage?.documentMerges || 0
  const quota = subscription?.currentPlan?.documentMerges || 0

  const baseMerges = Math.min(usage, quota)
  const additionalMerges = Math.max(usage - quota, 0)
  const usagePercentage = Math.round((usage / quota) * 100)
  const percentageOfQuota = usagePercentage < 100 ? usagePercentage : (quota / usage) * 100

  const percent = usagePercentage > 100 ? percentageOfQuota : usagePercentage

  if (isLoading) return <Loading minHeight='100px' fontSize='20px' />

  return (
    <div
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        paddingTop: '1em',
        paddingBottom: '1em',
        ...style,
      }}
      {...rest}
    >
      <div style={{ textAlign: 'center', fontSize, marginBottom: fontSize }}>
        <Text>{Math.max(quota - usage, 0)} Merges Remaining</Text>
      </div>

      <div style={{ marginBottom: fontSize, display: 'flex', justifyContent: 'center' }}>
        <Progress
          percent={percent}
          gapDegree={30}
          strokeWidth={strokeWidth}
          strokeColor='#009BFF'
          width={170}
          format={() => usagePercentage + '%'}
          type={type}
          size='small'
          trailColor={usagePercentage < 100 ? trailColor : '#FFCF19'}
          showInfo={showValue}
          {...progressProps}
        />
      </div>

      {usagePercentage > 100 && (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
          <Badge color='#009BFF' text={<Text style={{ fontSize }}>{baseMerges} Base merges</Text>} />
          {additionalMerges !== 0 && (
            <Badge color='#FFCF19' text={<Text style={{ fontSize }}>{additionalMerges} Additional merges</Text>} />
          )}
        </div>
      )}
    </div>
  )
}

SubscriptionUsageChart.defaultProps = {
  showValue: true,
  type: 'line',
  strokeWidth: 12,
  progressProps: {},
  style: {},
  trailColor: '#fff',
  fontSize: '.8em',
}
