import React from 'react'
import { Form, Input, Typography, Space, Button } from 'antd'
import { isFunction } from 'lodash'

import { DEFAULT_ACCOUNT } from '../consts'
const { Text } = Typography

export default function AccountForm(props) {
  const { account, onSubmit, isUpdating, onCancel } = props
  const { name, address = {} } = account.company
  const { street1, street2, city, state, zipCode, country } = address

  const handleSubmit = (formValues) => {
    isFunction(onSubmit) && onSubmit(formValues)
  }

  const handleCancel = () => {
    isFunction(onCancel) && onCancel()
  }

  const fields = [
    { name: 'company.name', value: name },
    { name: 'company.address.street1', value: street1 },
    { name: 'company.address.street2', value: street2 },
    { name: 'company.address.city', value: city },
    { name: 'company.address.state', value: state },
    { name: 'company.address.zipCode', value: zipCode },
    { name: 'company.address.country', value: country },
  ]

  return (
    <Form layout='vertical' fields={fields} onFinish={handleSubmit}>
      <Form.Item name='company.name' label='Company Name'>
        <Input placeholder='Company Name' />
      </Form.Item>

      <Text strong={true}>Address</Text>

      <Form.Item name='company.address.street1' label='Street'>
        <Input placeholder='Street 1' />
      </Form.Item>

      <Form.Item name='company.address.street2' label='Street 2'>
        <Input placeholder='Street 2' />
      </Form.Item>

      <Form.Item name='company.address.city' label='City'>
        <Input placeholder='City' />
      </Form.Item>

      <Form.Item name='company.address.state' label='State'>
        <Input placeholder='State' />
      </Form.Item>

      <Form.Item name='company.address.zipCode' label='Zip Code'>
        <Input placeholder='Zip Code' />
      </Form.Item>

      <Form.Item name='company.address.country' label='Country'>
        <Input placeholder='Country' />
      </Form.Item>

      <Form.Item className='text-center'>
        <Space direction='horizontal'>
          <Button onClick={handleCancel} type='text' loading={isUpdating}>
            Cancel
          </Button>
          <Button htmlType='submit' type='primary'>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

AccountForm.defaultProps = {
  account: DEFAULT_ACCOUNT,
  onUpdate: () => {},
  onCancel: () => {},
}
