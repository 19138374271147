import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as regularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import Icon from '@ant-design/icons'

export default function FaIcon({ iconStyle, icon, ...rest }) {
  if (typeof icon === 'string') icon = regularSvgIcons[icon] || <></>

  return (
    <Icon
      {...rest}
      component={() => (
        <FontAwesomeIcon icon={icon} style={{ position: 'relative', top: '-.125em', ...(iconStyle || {}) }} />
      )}
    />
  )
}
