import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useHttp } from './httpHooks'
import config from '../config/index.js'
import { debounce, merge as mergeObjs, isEqual, cloneDeep } from 'lodash'
import { getJsonSchemaFromTemplate } from '../utils'
import { notification } from 'antd'

const apiUrl = config.apiUrl
const apiEndpoint = `${apiUrl}/templates`

export const useTemplate = (id) => {
  const idRef = useRef()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [previewData, setPreviewData] = useState(null)
  const [previewError, setPreviewError] = useState(null)
  const [previewIsLoading, setPreviewIsLoading] = useState(false)
  const { get, post, put } = useHttp()
  const templateUrl = `${apiEndpoint}/${id}`

  const load = useCallback(
    async (id, { select } = {}) => {
      try {
        if (!id && typeof id === 'string') throw new Error('Invalid template id')
        setIsLoading(true)
        setError(null)
        const params = { select }
        const response = await get(`${apiEndpoint}/${id}`, { params })
        setData(response.data)
        return [null, response.data]
      } catch (error) {
        setError(error.response)
        return [error.response, null]
      } finally {
        setIsLoading(false)
      }
    },
    [get]
  )
  const reload = useCallback(() => {
    if (!data?.id) return
    load(data.id)
  }, [data, load])

  useEffect(() => {
    if (!id || isEqual(id, idRef.current)) return
    idRef.current = id
    load(id)
  }, [id, load])

  const create = useCallback(
    async (templateData = {}) => {
      try {
        const { data } = await post(apiEndpoint, templateData)
        return [null, data]
      } catch (error) {
        notification.error({ message: 'Unable to create template' })
        return [error, null]
      }
    },
    [post]
  )

  const updateTemplate = useCallback(
    (value) => {
      setError(null)
      setIsSaving(true)
      put(`${apiEndpoint}/${id}`, value)
        .then(({ data }) => {
          setData((prev) => ({ ...prev, __v: data.__v }))
        })
        .catch((error) => {
          setError(error.response)
        })
        .finally(() => setIsSaving(false))
    },
    [put, id]
  )

  const updateDebounce = useMemo(() => debounce(updateTemplate, 1000), [updateTemplate])

  const update = useCallback(
    (value) => {
      const prevData = { ...data }
      try {
        const update = { ...data, ...value }

        if (isEqual(update, data)) return [null, data]

        if (value.html || value.headerTemplate || value.footerTemplate || value.documentNamePattern) {
          const html = value.html || data.html
          const headerTemplate = value.headerTemplate || data.headerTemplate
          const footerTemplate = value.footerTemplate || data.footerTemplate
          const documentNamePattern = value.documentNamePattern || data.documentNamePattern

          const bodySchema = getJsonSchemaFromTemplate(html)
          const headerSchema = getJsonSchemaFromTemplate(headerTemplate)
          const footerSchema = getJsonSchemaFromTemplate(footerTemplate)
          const documentNameSchema = getJsonSchemaFromTemplate(documentNamePattern)
          value.fieldSchema = mergeObjs({}, bodySchema, headerSchema, footerSchema, documentNameSchema)
        }

        updateDebounce({ ...prevData, ...value })
        setData((prev) => ({ ...prev, ...value }))
        return [null, update]
      } catch (error) {
        setData(prevData)
        return [error, prevData]
      }
    },
    [data, updateDebounce]
  )

  const merge = useCallback(
    (data, { isPreview = false } = {}) =>
      new Promise((resolve, reject) => {
        const body = data
        const requestOptions = { headers: { accept: 'application/pdf' }, responseType: 'arraybuffer' }
        post(`${templateUrl}/content?isPreview=${isPreview}`, body, requestOptions)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      }),
    [templateUrl, post]
  )

  const mergeAirtable = useCallback(
    async (templateId, params) => {
      try {
        const config = {
          params,
          headers: { accept: 'application/json', 'X-Documint-Integration': 'airtable/generation-link' },
        };
        const response = await post(`${apiEndpoint}/${templateId}/content/airtable`, {}, config)
        return [null, response]
      } catch (error) {
        return [error.response, null]
      }
    },
    [post]
  )

  const createCloneKey = useCallback(async () => {
    try {
      const response = await post(`${templateUrl}/clone-key`)
      const { cloneKey } = response.data
      setData((prev) => ({ ...prev, cloneKey }))
      return [null, cloneKey]
    } catch (error) {
      return [error, null]
    }
  }, [post, templateUrl])

  const api = {
    load,
    reload,
    create,
    update,
    merge,
    mergeAirtable,
    createCloneKey,
  }

  /**
   * Creates document preview
   */
  const createPreview = useCallback(
    async (template, mergeData) => {
      try {
        setPreviewIsLoading(true)
        if (!data) throw new Error('No template data')

        if (!template) template = data
        if (!mergeData) mergeData = data.testData

        const { html, css, headerTemplate, footerTemplate, options } = template
        const body = { template: { html, css, headerTemplate, footerTemplate, options }, data: mergeData }
        const requestOptions = { headers: { accept: 'application/pdf' }, responseType: 'arraybuffer' }
        const response = await post(`${apiEndpoint}/preview`, body, requestOptions)

        setPreviewData(response.data)

        return [null, response.data]
      } catch (error) {
        setPreviewError(error.response)
        return [error.response, null]
      } finally {
        setPreviewIsLoading(false)
      }
    },
    [post, data]
  )

  const preview = {
    data: previewData,
    error: previewError,
    isLoading: previewIsLoading,
    create: createPreview,
  }

  return { data, error, isLoading, isSaving, ...api, preview }
}

/**
 *
 * @param {Object} [criteria] if provided will automatically load template list
 * @param {String} [criteria.sort] comma separated list of fields to order by
 * @param {String} [criteria.filter]
 * @param {String} [criteria.select] fields to select
 * @param {Number} [criteria.page] page number
 * @param {Number} [criteria.limit] limit number of results
 * @returns
 */
export const useTemplates = (criteria) => {
  // useRef to store current criteria between renders. Initial value undefined so it runs the first time
  const criteriaRef = useRef()
  const { get, post, put, del: httpDelete } = useHttp()
  const [data, setData] = useState({ data: [] })
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(criteria !== undefined)

  /**
   * Gets a list of templates
   * @param {Object} [criteria] query criteria
   * @param {String} [criteria.sort] comma separated list of fields to sort by
   * @param {Number} [criteria.page] page number to return. 1 based
   * @param {Number} [criteria.limit] Number of results to limit to
   * @param {Number} [criteria...rest] Fields to filter by
   */
  const list = useCallback(
    async ({ sort, page = 1, select, limit, ...rest } = {}) => {
      setIsLoading(true)
      try {
        let params = { sort, page, limit, select, ...rest }
        const response = await get(apiEndpoint, { params })
        const { data } = response
        setData(data)
        return [null, data]
      } catch (error) {
        console.error(error)
        setError(error.response || error)
        return [error.response, null]
      } finally {
        setIsLoading(false)
      }
    },
    [get]
  )

  useEffect(() => {
    if (!criteria || isEqual(criteria, criteriaRef.current)) return
    criteriaRef.current = criteria
    list(criteriaRef.current)
  }, [criteriaRef, criteria, list])

  /**
   * Creates a template
   * @param {Object} values New template values
   */
  const create = useCallback(
    async (values) => {
      const prevData = cloneDeep(data)
      try {
        setData({ ...prevData, data: [values, ...prevData.data] })
        const { data } = await post(apiEndpoint, values)
        setData({ ...prevData, data: [data, ...prevData.data] })
        return [null, data]
      } catch (error) {
        setData(prevData)
        console.error(error)
        notification.error({ message: 'Unable to create template' })
        return [error.response || error, null]
      }
    },
    [post, data]
  )

  /**
   * Updates a template in a list
   * @param {String} id template id
   * @param {Object} update update data
   */
  const update = useCallback(
    async (id, update) => {
      const prevData = cloneDeep(data)
      try {
        setData((p) => ({ ...p, data: p.data?.map((t) => (t.id === id ? { ...t, ...update } : t)) || p.data }))
        const { data } = await put(`${apiEndpoint}/${id}`, update)
        setData((p) => ({ ...p, data: p.data?.map((t) => (t.id === id ? { ...t, ...data } : t)) || p.data }))
        return [null, data]
      } catch (error) {
        console.error(error)
        notification.error({ message: 'Unable to update template' })
        setData(prevData)
        return [error.response || error, null]
      }
    },
    [put, data]
  )

  /**
   * Permanently Deletes a template
   * @param {String} id Template id
   */
  const del = useCallback(
    async (id) => {
      const prevData = cloneDeep(data)
      try {
        setData((p) => ({ ...p, data: p.data?.filter((t) => t.id !== id) || p.data }))
        await httpDelete(`${apiEndpoint}/${id}`)
        return [null, data]
      } catch (error) {
        setData(prevData)
        notification.error({ message: 'Unable to delete template' })
        return [error, null]
      }
    },
    [httpDelete, data]
  )

  /**
   * Renames a template
   * @param {String} id template id
   * @param {String} name new template name
   */
  const rename = useCallback(
    (id, name) => {
      return update(id, { name })
    },
    [update]
  )

  /**
   * Clones a template
   * @param {String} id template id to clone
   * @param {Object} [values] Data to set in the new template
   */
  const clone = useCallback(
    async (id, values = {}) => {
      const prevData = cloneDeep(data)
      try {
        const { data: templateToClone } = await get(`${apiEndpoint}/${id}`)
        const newTemplate = { ...templateToClone, ...values, name: `${templateToClone.name} (copy)` }
        delete newTemplate.createdAt
        delete newTemplate.updatedAt
        const { data: newTemplateData } = await post(apiEndpoint, newTemplate)
        setData((p) => ({ ...p, data: [newTemplateData, ...p.data] }))
        return [null, data]
      } catch (error) {
        setData(prevData)
        notification.error({ message: 'Unable to clone template' })
        return [error, null]
      }
    },
    [get, post, data]
  )

  const cloneFromUrl = useCallback(
    (url) => {
      return post(url)
    },
    [post]
  )

  /**
   * Moves a template to trash
   * @param {String} id Template id
   */
  const trash = useCallback(
    (id) => {
      return update(id, { isTrashed: true, isActive: false })
    },
    [update]
  )

  /**
   * Restores a template from trash
   * @param {String} id Template id
   */
  const restore = useCallback(
    (id) => {
      return update(id, { isTrashed: false })
    },
    [update]
  )

  const dataList = { data: data.data, totalCount: data.totalCount, error, isLoading }
  const api = { list, create, update, del, rename, clone, cloneFromUrl, trash, restore }

  return { ...dataList, error, isLoading, ...api }
}

export const useTemplatesGallery = () => {
  const { get } = useHttp()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const load = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await get(`${apiEndpoint}/gallery`, {
        params: {
          select: 'id,name,components,styles,html,css,headerTemplate,footerTemplate,thumbnail,fieldSchema',
          sort: 'order',
        },
      })
      setData(data)
      return [null, data]
    } catch (error) {
      setError(error.response)
      return [error.response, null]
    } finally {
      setIsLoading(false)
    }
  }, [get])

  useEffect(() => {
    load()
  }, [load])

  return { data, error, isLoading }
}

const templateHooks = { useTemplate, useTemplates, useTemplatesGallery }

export default templateHooks
