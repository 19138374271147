import React from 'react'
import { List, Skeleton, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'

const { Title } = Typography

export default function DetailList({ items, title, isLoading, loading, renderItem, pagination, ...rest }) {
  isLoading = isLoading === true || isLoading === false ? isLoading : loading
  if (isLoading === false) isLoading = undefined
  renderItem =
    renderItem ||
    (({ label = '', description, value = '', tooltip, actions }) => (
      <List.Item actions={actions}>
        <Skeleton avatar title={false} loading={isLoading} active>
          <List.Item.Meta
            title={
              <>
                {label}{' '}
                {tooltip && (
                  <Tooltip title={tooltip}>
                    <InfoCircleOutlined />
                  </Tooltip>
                )}
              </>
            }
            description={description}
          />
          <div style={{ textAlign: 'right' }}>{value}</div>
        </Skeleton>
      </List.Item>
    ))

  return (
    <List
      header={title && <Title level={5}>{title}</Title>}
      dataSource={items}
      renderItem={renderItem}
      itemLayout='horizontal'
      loading={
        pagination !== undefined && isLoading
          ? { indicator: <LoadingOutlined spin style={{ fontSize: '36px' }} /> }
          : undefined
      }
      pagination={pagination}
      {...rest}
    />
  )
}

DetailList.defaultProps = {
  items: [],
  title: '',
  isLoading: undefined,
}
