import { documintApi } from '.'

export const integrationsApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getIntegrations: build.query({
      query: () => '/account?select=airtable',
      transformResponse: (data) => ({ airtable: data.airtable }),
      providesTags: ['Integrations'],
    }),
    addIntegration: build.mutation({
      query: ({ airtable }) => ({ url: '/account', method: 'PUT', body: { airtable } }),
      invalidatesTags: ['Integrations'],
    }),
    deleteIntegration: build.mutation({
      query: (name) => ({ url: '/account', method: 'PUT', body: { [name]: { apiKey: null } } }),
      invalidatesTags: ['Integrations'],
    }),
  }),
})

export const { useGetIntegrationsQuery, useAddIntegrationMutation, useDeleteIntegrationMutation } = integrationsApi

export default integrationsApi
