import React from 'react'
import { Alert } from 'antd'
import steps from './steps'
import { useCheckoutContext } from '../../context/CheckoutContext'
import { PaymentMethodsProvider } from '../../context/PaymentMethodsContext'

export default function CheckoutProcess() {
  const { stepIndex } = useCheckoutContext()

  const step = steps[stepIndex]

  return (
    <Alert.ErrorBoundary>
      <PaymentMethodsProvider>
        <div style={{ width: '100%', maxWidth: '1200px' }}>{step.content}</div>
      </PaymentMethodsProvider>
    </Alert.ErrorBoundary>
  )
}
