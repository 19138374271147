import React from 'react'
import { Card, Typography, List, Button } from 'antd'
import isFunction from 'lodash/isFunction'
import merge from 'lodash/merge'

import { formatNumber } from '@utils'

const { Title, Text, Paragraph } = Typography

const intervals = {
  monthly: { name: 'monthly', abbr: 'mo.', singular: 'month' },
  yearly: { name: 'yearly', abbr: 'yr.', singular: 'year' },
}

const defaultPlan = {
  name: 'Failed to Load Plan',
  description: '',
  price: 0,
  items: [],
  features: [],
  documentMerges: 0,
}

export default function PlanCard(props) {
  const { data, showDetail, onSelect, selectable, showButton, buttonText, buttonProps, ribbonProps, ...rest } = props
  const { name, price, interval, documentMerges, features, pricePerAdditional } = data || defaultPlan

  const planInterval = intervals[interval]
  const featureList = [...features]

  if (pricePerAdditional) featureList.unshift({ title: `$${pricePerAdditional} ea. additional merge` })

  if (documentMerges) featureList.unshift({ title: `${documentMerges}+ documents` })

  const formattedQuota = documentMerges && formatNumber(documentMerges, '0,0')

  const handleSelect = () => selectable === true && isFunction(onSelect) && onSelect(data)

  const allCardProps = merge(
    {
      title: <Title level={4}>{name}</Title>,
      headStyle: { textAlign: 'center' },
      hoverable: selectable,
      bordered: false,
      onClick: handleSelect,
      style: { display: 'flex', flexDirection: 'column', flexBasis: '100%', borderRadius: '1em' },
      bodyStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        textAlign: 'center',
        flex: '1 1 auto',
      },
    },
    rest
  )

  return (
    <Card {...allCardProps}>
      <div style={{ flex: '1 1 auto' }}>
        {formattedQuota && (
          <div>
            <Title level={4} style={{ marginBottom: '0' }}>
              {formattedQuota} Documents <br />
            </Title>
            <Text type='secondary'>per {planInterval.singular}</Text>
          </div>
        )}

        <div style={{ margin: '2em 0 0 0' }}>
          <Title level={1} className='mb-0' style={{ fontSize: '5em' }}>
            {typeof price === 'number' ? (
              <>
                <sup>$</sup>
                {formatNumber(price, '0,0[.]00')}
              </>
            ) : (
              price
            )}
          </Title>

          {planInterval && <Paragraph type='secondary'>billed {planInterval.name} </Paragraph>}
        </div>

        {showDetail && featureList && (
          <List
            dataSource={featureList}
            renderItem={({ title = '' } = {}) => <List.Item style={{ justifyContent: 'center' }}>{title}</List.Item>}
            rowKey='id'
            style={{ marginTop: '2em' }}
          />
        )}
      </div>

      {showButton && (
        <div style={{ display: 'flex', marginTom: '1em' }}>
          <Button onClick={handleSelect} size='large' block type='primary' disabled={!selectable} {...buttonProps}>
            {buttonText}
          </Button>
        </div>
      )}
    </Card>
  )
}

PlanCard.defaultProps = {
  data: {},
  buttonText: 'Select',
  selectable: true,
  showDetail: true,
  buttonProps: {},
  showButton: true,
  onSelect: () => {},
}
