const { NODE_ENV = 'development', PORT = '3000' } = process?.env || {}

const defaults = {
  name: 'Documint',
  env: NODE_ENV,
  port: PORT,
  documentationUrl: 'https://docs.documint.me',
  apiReferenceUrl: 'https://documenter.getpostman.com/view/11741160/TVK5cLxQ',
  magicBellApiKey: '533db610eed740e6dc20c9c1f2c5824a997dc474',
  reCaptchaKey: process?.env.REACT_APP_RE_CAPTCHA_KEY,
}

const development = {
  apiUrl: process?.env.REACT_APP_API_URL || 'https://dev.api.documint.me/1',
  baseUrl: process?.env.REACT_APP_BASE_URL || 'https://dev.app.documint.me',
}

const production = {
  apiUrl: process?.env.REACT_APP_API_URL || 'https://api.documint.me/1',
  baseUrl: process?.env.REACT_APP_BASE_URL || 'https://app.documint.me',
}

const config = {
  development: { ...defaults, ...development },
  production: { ...defaults, ...production },
}[NODE_ENV]

export default config
