import React from 'react'

import { Layout } from 'antd'

export default function MainLayout({ children, className, ...rest }) {
  return (
    <Layout className={'layout-main ' + className} style={{ height: '100vh' }} {...rest}>
      {children}
    </Layout>
  )
}
MainLayout.defaultProps = {
  className: '',
}
