import React from 'react'
import { Alert, Button } from 'antd'
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'

import FaIcon from '@components/icons/FaIcon'

export default function DeleteAccountAlert({ onClick, ...rest }) {
  return (
    <Alert
      message='Danger Zone'
      description='Be careful, this action is permanent.'
      icon={<FaIcon icon={faTriangleExclamation} />}
      type='error'
      showIcon={true}
      action={
        <Button type='outline' key='0' danger onClick={onClick}>
          Delete Account
        </Button>
      }
      {...rest}
    />
  )
}
DeleteAccountAlert.defaultProps = {
  onClick: () => {},
}
