import { useState, useEffect, useCallback } from 'react'
import http from '../services/httpService'
import config from '../config/index.js'
import isNil from 'lodash/isNil'

const apiUrl = config.apiUrl
const baseUrl = `${apiUrl}/documents`

const defaultState = { data: {}, isPasswordProtected: false }

export const useDocument = (id, options) => {
  const [data, setData] = useState(defaultState)
  const [isLoading, setIsLoading] = useState(!isNil(id))
  const [error, setError] = useState(null)

  /**
   * Load Document
   * @param {String} id
   * @param {Object} [options]
   * @param {String} [options.password] Document password
   */
  const load = useCallback(async (id, password, options = {}) => {
    try {
      setIsLoading(true)
      setError(null)
      if (password) options.headers = options.headers ? { ...options.headers, password } : { password }
      const { data, headers } = await http.get(`${baseUrl}/${id}`, options)
      const logoUrl = headers['x-company-logo-url']
      setData((prev) => ({ ...prev, data, isPasswordProtected: false, logoUrl }))
      return [null, data]
    } catch (error) {
      const { response = {} } = error || {}
      const update = {}

      if (response?.headers) update.logoUrl = response.headers['x-company-logo-url']
      if (response?.status === 401) update.isPasswordProtected = true
      setData({ ...defaultState, ...update })
      setError(response)
      return [response, null]
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!id) return
    load(id, options)
  }, [id, options, load])

  const update = async () => {}
  const del = async () => {}

  return { ...data, isLoading, error, load, update, del }
}

export const useDocuments = ({ filter, sort, page = 1, select, limit } = {}) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    list({ filter, sort, page, select, limit })
  }, [filter, sort, page, select, limit])

  async function list({ filter, sort, page = 1, select, limit } = {}) {
    setIsLoading(true)
    setError(null)
    try {
      const response = await http.get(baseUrl, { params: { filter, sort, page, limit, select } })
      const { data } = response
      setData(data.data)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const api = {
    list,
  }

  return [{ data, isLoading, error }, api]
}

const documentHooks = { useDocument, useDocuments }

export default documentHooks
