import { useState, useCallback, useEffect, useRef } from 'react'
import { Grid } from 'antd'
import useScale from './useScale'

const { useBreakpoint: useBp } = Grid

export const useDownload = () => {
  const download = (data, { type = 'application/pdf', filename = 'download', onSuccess }) => {
    const blob = new Blob([data], { type })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    if (onSuccess) onSuccess()
  }
  return download
}

export const useToggle = (defaultState) => {
  const [state, setState] = useState(defaultState)

  const toggleState = useCallback((value) => {
    setState((prev) => (typeof value === 'boolean' ? value : !prev))
  }, [])

  return [state, toggleState]
}

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState('lg')
  const screens = useBp()

  useEffect(() => {
    const bpOrder = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
    const currentBps = Object.entries(screens)
      .filter(([k, v]) => !!v)
      .map(([k]) => k)
    const currentBp = bpOrder.reverse().find((bp) => currentBps.includes(bp))
    if (currentBp && currentBp !== breakpoint) setBreakpoint(currentBp)
  }, [screens, breakpoint])

  return breakpoint
}

export function useTimeout(callback, delay) {
  const callbackRef = useRef(callback)
  const timeoutRef = useRef()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay)
  }, [delay])

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
  }, [])

  useEffect(() => {
    set()
    return clear
  }, [delay, set, clear])

  const reset = useCallback(() => {
    clear()
    set()
  }, [clear, set])

  return { reset, clear }
}

export function useDebounce(callback, delay, dependencies) {
  const { reset, clear } = useTimeout(callback, delay)
  useEffect(reset, [...dependencies, reset])
  useEffect(clear, [])
}

const allHooks = { useDownload, useToggle, useBreakpoint, useTimeout, useDebounce, useScale }

export default allHooks