import React from 'react'
import { Upload } from 'antd'

export default function CustomUpload({
  name,
  action,
  accept,
  withCredentials,
  showUploadList,
  onSuccess,
  onError,
  children,
  ...rest
}) {
  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      onSuccess(info.file.response.data)
    } else if (info.file.status === 'error') {
      onError(info.file)
    }
  }

  return (
    <Upload
      onChange={onChange}
      name={name}
      accept={accept}
      action={action}
      withCredentials={withCredentials}
      showUploadList={showUploadList}
      {...rest}
    >
      {children}
    </Upload>
  )
}

CustomUpload.defaultProps = {
  name: 'file',
  action: ``,
  withCredentials: true,
  showUploadList: false,
}
