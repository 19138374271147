import React from 'react'

import { useSubscriptionContext } from '@features/subscription'
import { formatNumber, formatDate } from '@utils/data'
import DetailList from '@components/DetailList'

export default function SubscriptionBillingDetails({ ...props }) {
  const { subscription, isLoading } = useSubscriptionContext()
  const { upcomingInvoice } = subscription

  const upcomingInvoiceItems = [
    { label: <b>Upcoming Invoice</b> },
    { label: 'Due Date', value: formatDate(upcomingInvoice?.date) },
    { label: 'Amount (est.)', value: formatNumber(upcomingInvoice?.amount, '$0,0.00') },
  ]

  return <DetailList items={upcomingInvoiceItems} isLoading={isLoading} />
}

SubscriptionBillingDetails.defaultProps = {}
