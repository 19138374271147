import _ from 'lodash'
import { format as dateFormat } from 'date-fns'
import numeral from 'numeral'

/**
 * Gets the difference between two objects
 * @param {Object} a first object
 * @param {Object} b second object
 * @param {String[]} [paths] list of paths to check
 * @returns
 */
export function getDifferences(a, b, paths) {
  if (!a || !b || typeof a !== 'object' || typeof b !== 'object') return

  if (paths) {
    a = _.pick(a, paths)
    b = _.pick(b, paths)
  }

  if (Object.keys(a).length <= 0) return paths || Object.keys(b)

  return Object.entries(a).reduce((acc, [key, value]) => {
    let bVal = b[key]
    if (!bVal) {
      acc.push(key)
      return acc
    }

    if (typeof bVal === 'object') bVal = JSON.stringify(bVal)
    const aVal = typeof value === 'object' ? JSON.stringify(value) : value
    if (aVal !== bVal) acc.push(key)

    return acc
  }, [])
}

/**
 * Returns an object with only the changes
 * @param {Object} originalObject
 * @param {Object} newObject
 * @param {String[]} [paths] specific paths to compare
 * @returns {Object} changed values
 */
export function getObjectChanges(originalObject, newObject, paths) {
  if (!originalObject || !newObject || typeof originalObject !== 'object' || typeof newObject !== 'object') return

  if (paths) {
    originalObject = _.pick(originalObject, paths)
    newObject = _.pick(newObject, paths)
  }

  if (Object.keys(originalObject).length <= 0) return paths || Object.keys(newObject)

  return Object.entries(newObject).reduce((acc, [key, value]) => {
    const originalValue = originalObject[key]
    const newValue = newObject[key]

    // If the new key doesn't exist in the original object
    // add it to the result and exit
    if (!originalValue) {
      acc[key] = newValue
      return acc
    }

    let newCompareValue = typeof newValue === 'object' ? JSON.stringify(newValue) : newValue
    let originalCompareValue = typeof originalValue === 'object' ? JSON.stringify(originalValue) : originalValue

    if (newCompareValue !== originalCompareValue) acc[key] = newValue
    return acc
  }, {})
}

// check if the passed value is a number
export function isNumber(value) {
  return typeof value === 'number' && !isNaN(value)
}

/**
 * Gets all of the paths of an object including nested paths
 * @param {Object} object
 * @param {String} [prevPath]
 * @returns
 */
export function getPaths(object, prevPath) {
  if (!object || (object instanceof Object && Object.keys(object).length === 0)) return

  if (typeof object === 'string') {
    try {
      object = JSON.parse(object)
    } catch (error) {
      return []
    }
  }

  const paths = []
  const keys = Object.keys(object)

  keys.forEach((key) => {
    const path = prevPath ? `${prevPath}.${key}` : key
    const prop = object[key]

    if (Array.isArray(prop)) return

    if (prop instanceof Object) {
      const childPaths = getPaths(prop, path)
      if (childPaths) paths.push(...childPaths)
    } else {
      paths.push(path)
    }
  })

  return paths
}

/**
 * Checks if object exists in a collection
 * @param {Object} obj object to search for
 * @param {Object[]} collection collection to search in
 * @returns Boolean
 */
export function existsInCollection(obj, collection) {
  if (!_.isObject(obj)) throw new TypeError('Invalid argument. Must be of type Object')
  if (!Array.isArray(collection)) throw new TypeError('Invalid argument. Must be of type Array')
  return !collection.every((item) => !_.isEqual(obj, item))
}

/**
 * Removes duplicate objects from collection
 * @param {Object[]} collection
 */
export function uniqueCollection(collection) {
  if (!Array.isArray(collection)) throw new TypeError('Collection must be type of Array')

  return collection.reduce((acc, cur) => {
    if (!existsInCollection(cur, acc) || acc.length === 0) acc.push(cur)
    return acc
  }, [])
}

/**
 * Used to compare components when memoized
 * @param {Object} prev
 * @param {Object} curr
 * @param {String[]}
 */
export function isUnchanged(prev, curr, paths) {
  if (!paths) return _.isEqual(prev, curr)

  const previousValues = _.pick(prev, paths)
  const currentValues = _.pick(curr, paths)

  const result = _.isEqual(previousValues, currentValues)
  return result
}

export function formatDate(date, format = 'MM/dd/yyyy') {
  if (!date) return
  const dateObj = _.isDate(date) ? date : new Date(date)
  return dateFormat(dateObj, format)
}

export function formatNumber(value, format = '0,0') {
  if (_.isNil(value)) return
  return numeral(value).format(format)
}
const dataUtils = {
  getDifferences,
  getObjectChanges,
  isNumber,
  getPaths,
  existsInCollection,
  uniqueCollection,
  isUnchanged,
  formatDate,
  formatNumber,
}

export default dataUtils