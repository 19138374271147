import React from 'react'
import Loading from '@components/common/Loading'

import PlanCard from './PlanCard'

export default function PlanCardList({ plans, isLoading, onSelect, renderItem }) {
  if (isLoading) return <Loading />

  const _renderItem =
    renderItem ||
    ((plan, index) => {
      return <PlanCard key={index} data={plan} onSelect={onSelect} />
    })

  return (
    <div style={{ overflow: 'auto', padding: '2em 1em' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowY: 'visible',
          justifyContent: 'flex-start',
          gap: '15px',
        }}
      >
        {plans.map(_renderItem)}
      </div>
    </div>
  )
}

PlanCardList.defaultProps = {
  plans: [],
  currentPlanId: null,
  nextPlanId: null,
  isLoading: false,
}
