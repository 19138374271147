import React from 'react'
import ReactDom from 'react-dom'
import HttpsRedirect from 'react-https-redirect'

import { Provider } from 'react-redux'
import store from './store'

import TagManager from 'react-gtm-module'
import App from './App.js'

import './App.less'
import './scss/main.scss'
import '@fortawesome/fontawesome-pro/scss/solid.scss'
import 'font-awesome/scss/font-awesome.scss'
import { openUserFeedbackModal } from './features/userFeedback/slice'

const tagManagerArgs = {
  gtmId: 'GTM-TSGCMXL',
  dataLayerName: 'TestDataLayer',
  dataLayer: {
    environment: 'development',
    auth: 'cA8o8eDYXKPlwipUtQyBsw',
    preview: 'env-3',
  },
}

TagManager.initialize(tagManagerArgs)

ReactDom.render(
  <Provider store={store}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </Provider>,
  document.getElementById('root')
)

//Expose openUserFeedbackModal to userguiding sidebar
window.addEventListener(
  'message',
  (event) => {
    if (event.origin !== window.location.origin || event.data !== 'openUserFeedbackModal') return
    store.dispatch(openUserFeedbackModal())
  },
  false
)
