import { documintApi } from '.'

export const profileApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query({
      query: (id) => `/users/me`,
      providesTags: (result, error, id) => [{ type: 'Profile', id }],
    }),

    updateProfile: build.mutation({
      query: ({ id, ...patch }) => ({ url: `/users/me`, method: 'PUT', body: patch }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          documintApi.util.updateQueryData('getProfile', id, (draft) => Object.assign(draft, patch))
        )

        queryFulfilled
          .then(({ data }) => {
            const { __v } = data
            dispatch(documintApi.util.updateQueryData('getProfile', id, (draft) => Object.assign(draft, { __v })))
          })
          .catch(patchResult.undo)
      },
      invalidatesTags: (result) => [{ type: 'Users', id: result.id }],
    }),
  }),
})

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApi

export default profileApi
