import React from 'react'
import { Card, Button, Dropdown } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

export default function TemplateCard(props) {
  const { template, showStatus, actions, height, onClick, ...rest } = props
  const { name, thumbnail = {}, isActive } = template

  const handleClick = (e) => {
    if (onClick) onClick(template, e)
  }

  const cover = (
    <div style={{ height, width: '100%', padding: '5px', overflow: 'hidden' }}>
      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'flex-start' }}>
        <img src={`${thumbnail.url}?${Date.now()}`} style={{ width: '100%' }} alt={name} />
      </div>
    </div>
  )

  const title = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={name}>
        {showStatus && (
          <span
            className={`template-status-indicator ${isActive && 'active'}`}
            title={isActive ? 'Active' : 'Inactive'}
          />
        )}
        {name}
      </div>

      {actions && (
        <ActionMenu template={template} trigger={['click']} items={actions}>
          <Button icon={<EllipsisOutlined rotate={90} />} type='text' className='action-menu-trigger' />
        </ActionMenu>
      )}
    </div>
  )

  return (
    <ActionMenu trigger={['contextMenu']} template={template} items={actions}>
      <Card cover={cover} onClick={handleClick} {...rest} className='grid-list__item'>
        <Card.Meta title={title} />
      </Card>
    </ActionMenu>
  )
}

TemplateCard.defaultProps = {
  template: {},
  showStatus: true,
  actions: null,
  height: '230px',
}

function ActionMenu({ template, items, children, onAction, ...rest }) {
  const handleClick = (event) => {
    const { domEvent } = event
    domEvent.stopPropagation()
    if (onAction) onAction(event, template)
  }

  return items ? (
    <Dropdown
      menu={{ items, onClick: handleClick }}
      placement='bottomRight'
      onClick={(e) => e.stopPropagation()}
      {...rest}
      className='action-menu'
    >
      {children}
    </Dropdown>
  ) : (
    children
  )
}

ActionMenu.defaultProps = {
  template: {},
  items: [],
}
