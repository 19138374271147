import React from 'react'
import { Layout, Typography, Row, Alert } from 'antd'

import { CheckoutProvider, CheckoutProcess } from '@features/subscription'

const { Title } = Typography
const { Content } = Layout

export default function Checkout() {
  return (
    <Alert.ErrorBoundary>
      <CheckoutProvider>
        <Layout style={{ height: '100%', position: 'relative' }}>
          <Content style={{ overflow: 'auto', padding: '5em 2em' }}>
            {/* PAGE HEADER */}
            <Title className='text-center'>Checkout</Title>

            {/* PAGE CONTENT */}
            <Row justify='center'>
              <CheckoutProcess />
            </Row>
          </Content>
        </Layout>
      </CheckoutProvider>
    </Alert.ErrorBoundary>
  )
}
