import React from 'react'
import CheckoutStepSelectPlan from './CheckoutStepSelectPlan'
import CheckoutStepPayment from './CheckoutStepPayment'
import CheckoutStepResult from './CheckoutStepResult'

const steps = [
  {
    title: 'Select your plan',
    content: <CheckoutStepSelectPlan />,
  },
  {
    title: 'Billing Info',
    content: <CheckoutStepPayment />,
  },
  {
    title: 'Checkout Completed',
    content: <CheckoutStepResult />,
  },
]

export default steps
