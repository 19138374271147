import { useState, useCallback } from 'react'
import pick from 'lodash/pick'
import { useHttp } from '../../../../../hooks/httpHooks'
import config from '../../../../../config'

const apiUrl = config.apiUrl
const apiEndpoint = `${apiUrl}/templates`

const usePreview = () => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const { post } = useHttp()

  const create = useCallback(
    async (template = {}, mergeData = {}) => {
      try {
        setError(null)
        setIsLoading(true)
        template = pick(template, ['html', 'css', 'headerTemplate', 'footerTemplate', 'options', 'renderEngine'])

        const body = { template, data: mergeData }
        const requestOptions = { headers: { accept: 'application/pdf' }, responseType: 'arraybuffer' }
        const response = await post(`${apiEndpoint}/preview`, body, requestOptions)

        setData(response.data)

        return [null, response.data]
      } catch (error) {
        setError(error.response)
        return [error.response, null]
      } finally {
        setIsLoading(false)
      }
    },
    [post]
  )

  return [create, { data, error, isLoading }]
}

export default usePreview
