import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  modalOpen: false,
}

const slice = createSlice({
  name: 'userFeedback',
  initialState,
  reducers: {
    openModal(state, action) {
      state.modalOpen = true
    },
    closeModal(state) {
      state.modalOpen = false
    },
  },
})

export const { openModal: openUserFeedbackModal, closeModal: closeUserFeedbackModal } = slice.actions

export const selectModalOpen = (state) => state.userFeedback.modalOpen

export default slice.reducer
