import React, { useState } from 'react'
import { Button, Tooltip, Typography, Popconfirm, notification, message } from 'antd'
import { useToggle } from '@hooks/helperHooks'
import isFunction from 'lodash/isFunction'

import { useGetApiAppsQuery, useDeleteApiAppMutation } from '@documintApi/api-apps'
import EditApiAppModal from './EditApiAppModal'
import FaIcon from '@components/icons/FaIcon'
import DetailList from '../../../components/DetailList'

const { Text } = Typography

export default function ApiAppList({ onUpdate, onDelete }) {
  const [editModalIsOpen, toggleEditApiAppModal] = useToggle(false)
  const [selectedId, setSelectedId] = useState(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const { data = {}, isFetching } = useGetApiAppsQuery({ page, limit: pageSize, sort: '-updatedAt' })
  const [del] = useDeleteApiAppMutation()
  const { data: apiApps = [], totalCount } = data

  const handlePageChange = (page, size) => {
    setPage(page)
    setPageSize(size)
  }

  const showEditApiAppModal = () => toggleEditApiAppModal(true)
  const hideEditApiAppModal = () => toggleEditApiAppModal(false)

  const handleSelectForEdit = (id) => {
    setSelectedId(id)
    showEditApiAppModal()
  }

  const handleUpdateSuccess = (value) => {
    setSelectedId(null)
    isFunction(onUpdate) && onUpdate(value)
    hideEditApiAppModal()
  }

  const handleUpdateCancel = () => {
    setSelectedId(null)
    hideEditApiAppModal()
  }

  const handleDelete = async (id) => {
    const { error } = await del(id)
    if (error) return notification.error({ message: 'Something went wrong', description: error?.data?.message })
    message.success({ content: 'Api key deleted' })
    isFunction(onDelete) && onDelete(id)
  }

  const items = apiApps.map(({ id, name, prefix }) => ({
    label: (
      <Tooltip title={`This Api Key starts with "${prefix}"`}>
        <Text type='secondary' code>
          {prefix}...
        </Text>{' '}
        {name}
      </Tooltip>
    ),

    actions: [
      <Button
        key='edit'
        type='text'
        icon={<FaIcon icon='faEdit' />}
        onClick={() => handleSelectForEdit(id)}
        title='Rename'
      />,
      <Popconfirm
        title={
          <>
            Are you sure you want to delete this Api Key? <br />
            Applications using this key will no longer <br /> have access to your Documint account.
          </>
        }
        okText='Delete permanently'
        okButtonProps={{ danger: true }}
        cancelText="Don't delete"
        onConfirm={() => handleDelete(id)}
      >
        <Button key='delete' icon={<FaIcon icon='faTrash' />} type='text' title='Delete' />
      </Popconfirm>,
    ],
  }))

  // API App List
  return (
    <>
      <DetailList
        items={items}
        isLoading={isFetching}
        pagination={{ current: page, pageSize, total: totalCount, onChange: handlePageChange, loading: true }}
      />
      <EditApiAppModal
        id={selectedId}
        open={editModalIsOpen}
        onFinish={handleUpdateSuccess}
        onCancel={handleUpdateCancel}
      />
    </>
  )
}
