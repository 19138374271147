/**
 *
 * @param {*} user
 */
export const identify = (user) => {
  if (!user || !user.id || !window?.userGuiding) return

  window.userGuiding.identify(user.id, {
    email: user.email,
    name: user.fullName,
    created_at: user.createdAt,
  })
}

const defaultExport = { identify }

export default defaultExport
