import React, { useState, createContext, useContext } from 'react'

import {
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useAttachPaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
} from '@documintApi/subscription'

const PaymentMethodsContext = createContext()

export function usePaymentMethodsContext() {
  return useContext(PaymentMethodsContext)
}

export const PaymentMethodsProvider = ({ children }) => {
  const { data = {}, isLoading } = useGetPaymentMethodsQuery()
  const { data: paymentMethods } = data
  const [isCreating, setIsCreating] = useState(false)
  const [attach, { isLoading: isAttaching }] = useAttachPaymentMethodMutation()
  const [setDefault, { isLoading: isSettingDefault }] = useSetDefaultPaymentMethodMutation()
  const [del, { isLoading: isDeleting }] = useDeletePaymentMethodMutation()

  const hasPaymentMethods = paymentMethods?.length > 0

  const value = {
    paymentMethods,
    isLoading,
    attach,
    isAttaching,
    setDefault,
    isSettingDefault,
    delete: del,
    isDeleting,
    hasPaymentMethods,
    isCreating,
    setIsCreating,
  }

  return <PaymentMethodsContext.Provider value={value}>{children}</PaymentMethodsContext.Provider>
}

const paymentMethodsContext = { PaymentMethodsProvider, usePaymentMethodsContext }

export default paymentMethodsContext
