import { pick, isNumber } from 'lodash'

export const vis = (function () {
  let stateKey
  let eventKey
  const KEYS = {
    hidden: 'visibilitychange',
    webkitHidden: 'webkitvisibilitychange',
    mozHidden: 'mozvisibilitychange',
    msHidden: 'msvisibilitychange',
  }
  for (stateKey in KEYS) {
    if (stateKey in document) {
      eventKey = KEYS[stateKey]
      break
    }
  }
  return function (callback) {
    const value = document['visibilityState'] === 'visible'
    if (callback) document.addEventListener(eventKey, (e) => callback(e, e.target.visibilityState === 'visible'))
    return value
  }
})()

/**
 * Sets the Editor device
 * @param {GJSEditor} editor editor instance
 * @param {Object} options template options
 * @returns
 */
export function setDevice(editor, options = {}) {
  if (!editor) return
  const { format = 'letter', isLandscape = false, width, height, margin } = options

  let deviceName = `${format} ${isLandscape ? 'Landscape' : 'Portrait'}`
  const deviceId = deviceName.toLowerCase().replace(' ', '-')

  const device = editor.DeviceManager.get(deviceId)
  let minHeight = device?.attributes?.height

  if (!format) {
    deviceName = `c-${Date.now()}`
    const deviceWidth = isLandscape ? height : width
    editor.DeviceManager.add(deviceName, deviceWidth, { withMedia: false })
    minHeight = isLandscape ? width : height
  }

  editor.setDevice(deviceName)

  if (!minHeight) return

  minHeight = `calc(${minHeight} - ${margin?.top} - ${margin?.bottom})`

  const wrapper = editor.getWrapper()

  if (wrapper?.view?.el) wrapper.view.el.style.minHeight = minHeight
}

/**
 * Sets the editor margins
 * @param {GJSEditor} editor GrapesJS editor instance
 * @param {Object} margin Template margins object
 * @returns
 */
export function setMargin(editor, margin) {
  try {
    if (!editor || !margin) return

    const body = editor.Canvas.getBody()
    if (!body) return
    const document = body.querySelector('.document')
    if (!document) return
    const { top = 0, right = 0, bottom = 0, left = 0 } = margin
    document.style.paddingTop = top
    document.style.paddingRight = right
    document.style.paddingBottom = bottom
    document.style.paddingLeft = left
  } catch (error) {
    console.error(error)
  }
}

export function deselectComponents(editor) {
  if (!editor) return
  const um = editor.UndoManager
  um.stop()
  editor.select()
  um.start()
}

export function setZoom(editor, value = 100) {
  if (!editor) return
  const min = 20
  const max = 300

  if (value < min) value = min
  if (value > max) value = max

  const { setZoom, setCoords, getElement } = editor.Canvas

  setZoom(value)

  const canvasEl = getElement()
  const framesWrapperEl = canvasEl.querySelector('.gjs-cv-canvas__frames')
  const percent = value / 100

  framesWrapperEl.style.width = `calc(100% / ${percent})`
  framesWrapperEl.style.height = `calc(100% / ${percent})`

  const xCoord = (canvasEl.offsetWidth - framesWrapperEl.offsetWidth) / 2
  const yCoord = (canvasEl.offsetHeight - framesWrapperEl.offsetHeight) / 2

  setCoords(xCoord, yCoord)
  return value
}

export function zoomIn(editor, amt = 10) {
  if (!editor || !isNumber(amt)) return
  const currentZoom = editor.Canvas.getZoom()
  return setZoom(editor, currentZoom + amt)
}

export function zoomOut(editor, amt = 10) {
  if (!editor || !isNumber(amt)) return
  const currentZoom = editor.Canvas.getZoom()
  return setZoom(editor, currentZoom - amt)
}

export function setEditorDimension(editor) {
  if (!editor || !editor.Canvas) return
  const frame = editor.Canvas.getFrameEl()
  const body = editor.Canvas.getBody()
  if (!frame || !body) return
  const document = body.querySelector('.document')
  if (!document) return
  const documentHeight = document.offsetHeight
  frame.style.height = `${documentHeight}px`
  editor.refresh()
}

export function hasChanged(a, b, paths) {
  if (!a || !b || typeof a !== 'object' || typeof b !== 'object') return

  if (paths) {
    a = pick(a, paths)
    b = pick(b, paths)
  }

  if (Object.keys(a).length !== Object.keys(b).length) return true

  return !Object.entries(a).every(([key, value]) => {
    let bVal = b[key]
    if (!bVal) return false
    if (typeof bVal === 'object') bVal = JSON.stringify(bVal)
    const aVal = typeof value === 'object' ? JSON.stringify(value) : value
    return aVal === bVal
  })
}

/**
 * Load data to editor
 * @param {grapesjs.Editor} editor
 * @param {grapesjs.Components} components
 * @param {grapesjs.Selector[]} styles Selectors from data
 * @param {grapesjs.Selector[]} style Canvas default selectors from config
 */
export const loadData = (editor, components, styles, style) => {
  editor.Components.clear()
  editor.loadData({ styles, components })
  style.forEach((s) => editor.Css.setRule(s.selector, s.style))
}

// Scan template for legacy components
export const loopAllComponents = (components, ifTrue, clb) => {
  components.each((component) => {
    if (component) {
      if (ifTrue(component)) {
        clb(component)
      } else {
        loopAllComponents(component.get('components'), ifTrue, clb)
      }
    }
  })
}

export const loopAllComponentsWithoutBreak = (components, clb) => {
  components.each((component) => {
    if (component) {
      clb(component)
      const comps = component.get('components')
      if (comps.length) {
        loopAllComponentsWithoutBreak(comps, clb)
      }
    }
  })
}

const on = (el, ev, fn, opts) => {
  ev = ev.split(/\s+/)
  el = el instanceof Array ? el : [el]

  for (let i = 0; i < ev.length; ++i) {
    el.forEach((elem) => elem && elem.addEventListener(ev[i], fn, opts))
  }
}

const off = (el, ev, fn, opts) => {
  ev = ev.split(/\s+/)
  el = el instanceof Array ? el : [el]

  for (let i = 0; i < ev.length; ++i) {
    el.forEach((elem) => elem && elem.removeEventListener(ev[i], fn, opts))
  }
}

const utils = {
  setMargin,
  setDevice,
  deselectComponents,
  vis,
  hasChanged,
  setEditorDimension,
  setZoom,
  zoomIn,
  zoomOut,
  loadData,
  loopAllComponents,
  on,
  off,
}

export default utils
